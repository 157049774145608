/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import {
  AppBar,
  Button,
  Container,
  Grid,
  Modal,
  Toolbar,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useWeb3React } from "@web3-react/core";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Web3 from "web3";
import DefaultTokens from "../config/default_tokens.json";
import { connectType, connectWalletStatus, networkValue } from "../constants";
import BNBIcon from "../Images/bscscan.png";
import Poocoinfav from "../Images/favicon.png";
import PoocoinIcon from "../Images/moon_logo.png";
import TelegramIcon from "../Images/telegram.svg";
import { tokenAddress } from "../Pages/TradeDex/state/tokenInfo/action";
import { AppContext } from "../Pages/TradeDex/utils/Utils";
import { poocoinBalance, poocoinLpv1, poocoinLpv2 } from "../PooCoin/index.js";
import { switchNetwork } from "../PooCoin/util";
import { injected, walletconnect } from "./walletConfiguration";

const useStyles = makeStyles((theme) => ({
  appBarSolid: {
    backgroundColor: "transparent",
    boxShadow: "none",
    padding: 15,
    textAlign: "center",
    borderBottom: "2px dotted #a4ff66",
    position: "initial",
  },
  Toolbar: {
    padding: "0px 50px 0px 50px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0px 10px 10px",
    },
  },
  linkGroup: {
    textAlign: "center",
    display: "content",
    [theme.breakpoints.down("xs")]: {
      display: "contents",
    },
  },
  link: {
    fontSize: 15,
    fontWeight: 600,
    margin: "0 9px 0px",
    display: "inline-block",
    textDecoration: "blink",
    color: "white",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  chainLinkGroup: {
    display: "grid",
    marginRight: 0,
  },
  chainLinkInput: {
    color: "#a4ff66",

    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
    "& svg": {
      display: "none",
    },
  },
  chainLink: {
    fontSize: 15,
    backgroundColor: "#0f1a1c!important",
    color: "white",
  },
  iconLink: {
    display: "flex",
    textAlign: "center",
    // color: "white",
    fontWeight: "bold",
    justifyContent: "center",
    textDecoration: "blink",
    color: "#a4ff66",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      marginRight: "10px",
    },
  },

  icon: {
    marginLeft: "5px",
    // height: "80px",
    width: "200px",
    cursor: "pointer",
    marginRight: "10px",
  },
  connect: {
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: "500",
    color: theme.palette.common.white,
    backgroundColor: "#fff",
    color: "#000",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center !important",
      display: "inherit !important",
    },
  },
  coinAmount: {
    color: "#adb5bd",
    textAlign: "left",
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "20px",
      textAlign: "left",
    },
  },
  amountColor: {
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#e9ecef",
    border: "none",
    boxShadow: theme.shadows[5],
    padding: "40px 30px 30px 30px",
    display: "grid",
    borderRadius: "8px",
  },
  connectBtn: {
    borderRadius: "4px",
    color: "#000",
    backgroundColor: "#a4ff66",
    marginBottom: ".5rem",
    padding: "5px",
    fontWeight: "700",
  },
  connectBtnDisable: {
    borderRadius: "4px",
    color: "#000",
    backgroundColor: "#a4ff66",
    marginBottom: ".5rem",
    padding: "5px",
    fontWeight: "700",
    cursor: "not-allowed!important",
  },
  rightLink: {
    fontSize: "13px",
    margin: "0px",
    padding: "0px",
  },
  headerIcon: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "10px",
    },
  },
}));

export default function Header(props) {
  const { account, connect, disconnect } = useContext(AppContext);

  const web3 = new Web3(window.ethereum);
  const { activate } = useWeb3React();
  const classes = useStyles();
  const { connectControl, changeNetwork, networkChainId } = props;
  const history = useHistory();
  const matches = useMediaQuery("(min-width:700px) and (max-width:1300px)");

  let connectStatus = localStorage.getItem("PoocoinConnectStatus"); //status connect to metamask
  if (connectStatus == null) {
    connectStatus = connectWalletStatus.disconnect;
    localStorage.setItem(
      "PoocoinConnectStatus",
      connectWalletStatus.disconnect
    );
  }

  let [userDisconnected, setUserDisconnected] = useState(connectStatus);

  useEffect(() => {
    account
      ? setUserDisconnected(connectWalletStatus.connect)
      : setUserDisconnected(connectWalletStatus.disconnect);
  }, [account]);

  // const { account, connect, reset, balance } = useWallet();
  const [poocoinBalanceData, setPoocoinBalanceData] = useState([]);
  const [poocoinLpv1Data, setPoocoinLpv1Data] = useState(0);
  const [poocoinLpv2Data, setPoocoinLpv2Data] = useState(0);
  const [priceData, setPriceData] = useState([]);
  const [network, setNetwork] = useState(
    localStorage.getItem("PoocoinChainId") != null
      ? localStorage.getItem("PoocoinChainId")
      : 1
  );
  const dispatch = useDispatch();

  const handleNetworkChange = (event) => {
    //select network chain
    // if (event.target.value == networkValue.Binance) {
    history.push("/");
    // } else if (event.target.value == networkValue.Polygon) {
    //   history.push("/polygon");
    // } else if (event.target.value == networkValue.Kuchain) {
    //   history.push("/kuchain");
    // } else if (event.target.value == networkValue.Ethereum) {
    //   history.push("/Ethereum");
    // }
    setNetwork(event.target.value);
    changeNetwork(event.target.value);
    // reset();
    setUserDisconnected(connectWalletStatus.disconnect);
    localStorage.setItem(
      "PoocoinConnectStatus",
      connectWalletStatus.disconnect
    );
  };

  const connectOrDisconnect = () => {
    //connect and disconnect button event
    localStorage.setItem("PoocoinChainId", networkChainId);
    if (userDisconnected == connectWalletStatus.connect) {
      disconnect();
      setUserDisconnected(connectWalletStatus.disconnect);
      localStorage.setItem(
        "PoocoinConnectStatus",
        connectWalletStatus.disconnect
      );
      // reset();
      poocoinBalance(account, poocoinBalanceValues);
    } else {
      connect();
      //success connect
      // setModalOpen(true);
    }
  };
  console.log(account, "account");

  const poocoinBalanceValues = (data) => {
    setPoocoinBalanceData(data);
  };
  const poocoinLpv1Values = (data) => {
    setPoocoinLpv1Data(data);
  };
  const poocoinLpv2Values = (data) => {
    setPoocoinLpv2Data(data);
  };

  const connectMethod = (value) => async () => {
    await connectControl(value); //metamask, walletconnect, binance-chain

    let currentChainId = parseInt(
      localStorage.getItem("PoocoinChainId") != null
        ? localStorage.getItem("PoocoinChainId")
        : 1
    );
    let metamaskChainId = parseInt(web3.currentProvider.chainId, 16);
    if (currentChainId != metamaskChainId) {
      await switchNetwork(currentChainId); //switch network in metamask
    } else {
      try {
        if (value == 1) {
          activate(injected);
        } else if (value == 2) {
          activate(walletconnect);
        } else {
          activate(injected);
        }
        // connect("injected");
      } catch (err) {
        // reset();
        console.log(err);
      }
      setUserDisconnected(connectWalletStatus.connect); //connect btn
      localStorage.setItem("PoocoinConnectStatus", 1);
    }
    setModalOpen(false);
  };

  useEffect(() => {
    if (!account && userDisconnected == connectWalletStatus.connect) {
      try {
        // connect();
        // activate(injected)

        poocoinBalance(account, poocoinBalanceValues);
        poocoinLpv1(account, poocoinLpv1Values);
        poocoinLpv2(account, poocoinLpv2Values);
      } catch (err) {
        console.log(err);
      }
    }
    fetch("https://min-api.cryptocompare.com/data/price?fsym=WETH&tsyms=USD")
      .then((res) => res.json())
      .then(async (data) => {
        // const QUERY = `{
        //   ethereum(network: ethereum) {
        //     dexTrades(
        //       options: {desc: ["block.height", "tradeIndex"], limit: 1}
        //       baseCurrency: {is: "${DefaultTokens.POOCOIN.address}"}
        //       quoteCurrency: {is: "${DefaultTokens.ETH.address}"}

        //     ) {
        //       transaction {
        //         hash
        //       }
        //       tradeIndex
        //       smartContract {
        //         address {
        //           address
        //         }
        //         contractType
        //         currency {
        //           name
        //         }
        //       }
        //       tradeIndex
        //       date {
        //         date
        //       }
        //       block {
        //         height
        //       }
        //       buyAmount
        //       buyAmountInUsd: buyAmount(in: USD)
        //       buyCurrency {
        //         symbol
        //         address
        //       }
        //       sellAmount
        //       sellAmountInUsd: sellAmount(in: USD)
        //       sellCurrency {
        //         symbol
        //         address
        //       }
        //       price
        //       sellAmountInUsd: sellAmount(in: USD)
        //       tradeAmount(in: USD)
        //       transaction {
        //         gasValue
        //         gasPrice
        //         gas
        //       }
        //     }
        //   }
        // }`;

        const QUERY = `
        {
          ethereum(network: ethereum) {
            dexTrades(
              baseCurrency: {is: "${DefaultTokens.POOCOIN.address}"}
              quoteCurrency: {is: "${DefaultTokens.ETH.address}"}
              options: {limit: 1, desc: ["block.timestamp.time"]}
            ) {
              block {
                height
                timestamp {
                  time(format: "%Y-%m-%d %H:%M:%S")
                }
              }
              transaction {
                index
              }
              baseCurrency {
                symbol
              }
              quoteCurrency {
                symbol
              }
              quotePrice
            }
          }
        }        
        `;

        const QUERY2 = `
        {
          ethereum(network: ethereum) {
            dexTrades(
              baseCurrency: {is: "${DefaultTokens.ETH.address}"}
              quoteCurrency: {is: "${DefaultTokens.BUSD.address}"}
              options: {limit: 1, desc: ["block.timestamp.time"]}
            ) {
              block {
                height
                timestamp {
                  time(format: "%Y-%m-%d %H:%M:%S")
                }
              }
              transaction {
                index
              }
              baseCurrency {
                symbol
              }
              quoteCurrency {
                symbol
              }
              quotePrice
            }
          }
        }        
        `;

        // -------- Endpoint ----------------------
        const endpoint = "https://graphql.bitquery.io/";

        // Function which fetches the data from the API
        const response = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-API-KEY": "BQYRgsg3l0o6F7hP74LyPMd657qC8Q2R",
          },
          body: JSON.stringify({
            query: QUERY,
          }),
        });

        const datas = await response.json();

        const response2 = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-API-KEY": "BQYRgsg3l0o6F7hP74LyPMd657qC8Q2R",
          },
          body: JSON.stringify({
            query: QUERY2,
          }),
        });

        const datas2 = await response2.json();

        let price =
          Number(datas.data.ethereum.dexTrades[0].quotePrice) *
          Number(datas2.data.ethereum.dexTrades[0].quotePrice);
        setPriceData(Number(price).toFixed(6));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [account, userDisconnected]);

  useEffect(() => {
    if (!account) return;
    poocoinBalance(account, poocoinBalanceValues);
    poocoinLpv1(account, poocoinLpv1Values);
    poocoinLpv2(account, poocoinLpv2Values);
  }, [account]);

  let coinAmount = "";
  let connectLabel = "Connect";
  if (account && userDisconnected == connectWalletStatus.connect) {
    coinAmount = (
      <div>
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://app.uniswap.org/#/swap?outputCurrency=${DefaultTokens.POOCOIN.address}`}
          className={classes.rightLink}
        >
          <div
            style={{
              display: matches && "flex",
              width: matches && "125px",
              columnGap: matches && "5px",
            }}
          >
            Your <img src={Poocoinfav} height="18" /> : {poocoinBalanceData}{" "}
            <span className={classes.amountColor}>
              ${parseFloat(poocoinBalanceData * priceData).toFixed(2)}
            </span>
          </div>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://app.uniswap.org/#/add/ETH/${DefaultTokens.POOCOIN.address}`}
          className={classes.rightLink}
        >
          <div
            style={{
              display: matches && "flex",
              width: matches && "185px",
              columnGap: matches && "5px",
            }}
          >
            Your <img src={Poocoinfav} height="18" />
            <img src={BNBIcon} height="15" /> LP V1:{" "}
            {parseFloat(poocoinLpv1Data).toFixed(2)}{" "}
            <span className={classes.amountColor}>
              ${parseFloat(poocoinLpv1Data * priceData).toFixed(2)}
            </span>
          </div>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://app.uniswap.org/#/add/ETH/${DefaultTokens.POOCOIN.address}`}
          className={classes.rightLink}
        >
          <div
            style={{
              display: matches && "flex",
              width: matches && "185px",
              columnGap: matches && "5px",
            }}
          >
            Your <img src={Poocoinfav} height="18" />
            <img src={BNBIcon} height="15" /> LP V2:{" "}
            {parseFloat(poocoinLpv2Data).toFixed(2)}{" "}
            <span className={classes.amountColor}>
              ${parseFloat(poocoinLpv2Data * priceData).toFixed(2)}
            </span>
          </div>
        </a>
      </div>
    );
    connectLabel = <span>Logout</span>;
  }

  const [open, setModalOpen] = React.useState(false);

  const modalClose = () => {
    setModalOpen(false);
  };

  return (
    <AppBar position="fixed" className={classes.appBarSolid}>
      <Toolbar className={classes.Toolbar}>
        <Container maxWidth="xl">
          <Grid container direction="row" alignItems="center">
            <Grid item lg={5} md={12} sm={12} xs={12}>
              <Grid container alignItems="center" className="starting">
                <Grid item>
                  <Link to="/" className={classes.iconLink}>
                    <img src={PoocoinIcon} className={classes.icon}></img>
                    (ETH)
                    {/* <span>
                      PooCoin <br />Charts
                    </span> */}
                  </Link>
                </Grid>

                {networkChainId == networkValue.Binance ? (
                  <Grid item className={classes.headerIcon}>
                    <Link
                      to={`/tokens/${DefaultTokens.POOCOIN.address}`}
                      onClick={
                        () =>
                          dispatch(
                            tokenAddress({
                              address: DefaultTokens.POOCOIN.address,
                            })
                          )

                        // dispatch({
                        //   type: "SET_TOKENADDRESS",
                        //   payload: DefaultTokens.POOCOIN.address,
                        // })
                      }
                    >
                      <img src={Poocoinfav} height="22" />

                      <span className={classes.amountColor}>${priceData} </span>
                    </Link>
                    <a
                      href="https://t.me/anon_logic"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={TelegramIcon} height="25" />
                    </a>
                  </Grid>
                ) : (
                  <Grid item>
                    <a
                      href="https://t.me/anon_logic"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={TelegramIcon} height="25" />
                    </a>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {networkChainId == networkValue.Binance && (
              <Grid
                item
                lg={4}
                md={12}
                sm={12}
                xs={12}
                container
                justifyContent={"center"}
                className="menu_part"
              >
                <div className={classes.linkGroup}>
                  <Link className={classes.link} to="/">
                    Charts
                  </Link>
                  {/* <Link className={classes.link} to="/swap">Trade</Link> */}
                  <Link className={classes.link} to="/multichart">
                    Multi&nbsp;Chart
                  </Link>
                  <Link className={classes.link} to="/about">
                    About
                  </Link>
                  {/* <a className={classes.link} target="_blank" href={`https://app.uniswap.org/#/swap?outputCurrency=0x0f304557d7F410385B45f3ce104B8f215126396D&inputCurrency=0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2`}> */}
                  <Link className={classes.link} to="/trade">
                    Trade
                  </Link>
                  {/* </a>                   */}
                  {/* <Link className={classes.link} to="/tools">
                    Tools
                  </Link> */}
                  {/* <Link className={classes.link} to="/premium">
                    Premium
                  </Link> */}
                  <Link className={classes.link} to="/promote">
                    Advertise
                  </Link>
                  {/* <Link className={classes.link} to="/" target="_blank">Free Price Bot</Link> */}
                  {/* <a
                    className={classes.link}
                    href="#"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Free Price Bot
                  </a> */}
                  {/* <a className={classes.link} href="/about">About</a>
                <a className={classes.link} href="/tools">Tools</a><br />
                <a className={classes.link} href="/premium">Premium</a>
                <a className={classes.link} href="/promote">Advertise</a>
                <a className={classes.link} href="https://t.me/Poocoin_Pricebot">Free&nbsp;Price&nbsp;Bot</a> */}
                </div>
              </Grid>
            )}
            {networkChainId == networkValue.Polygon && (
              <Grid
                item
                lg={3}
                md={12}
                sm={12}
                xs={12}
                container
                justifyContent={"center"}
                className="menu_part"
              >
                <div className={classes.linkGroup}>
                  <Link className={classes.link} to="/polygon">
                    Charts
                  </Link>
                  <Link className={classes.link} to="/polygonpromote">
                    Advertise
                  </Link>
                </div>
              </Grid>
            )}
            {networkChainId == networkValue.Kuchain && (
              <Grid
                item
                lg={3}
                md={12}
                sm={12}
                xs={12}
                container
                justifyContent={"center"}
                className="menu_part"
              >
                <div className={classes.linkGroup}>
                  <Link className={classes.link} to="/kuchain">
                    Charts
                  </Link>
                  <Link className={classes.link} to="/kuchainpromote">
                    Advertise
                  </Link>
                </div>
              </Grid>
            )}
            <Grid
              item
              md={2}
              sm={12}
              xl={2}
              className={classes.coinAmount}
              container
              justifyContent={"center"}
            >
              {coinAmount}
            </Grid>
            <Grid
              item
              lg={1}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent={"center"}
              className="connect_btns"
            >
              <Button
                variant="contained"
                className="conect"
                onClick={connectOrDisconnect}
              >
                {connectLabel}
              </Button>
              {/* <Button variant="contained" className={classes.connect} onClick={connectOrDisconnect}>{connectLabel}
              </Button> */}
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
      <Modal className={classes.modal} open={open} onClose={modalClose}>
        <div className={classes.paper}>
          <button
            className={classes.connectBtn}
            onClick={connectMethod(connectType.metamask)}
          >
            Metamask/TrustWallet
          </button>
          <button
            className={classes.connectBtn}
            onClick={connectMethod(connectType.walletconnect)}
          >
            WalletConnect
          </button>
          <button className={classes.connectBtn} onClick={modalClose}>
            Close
          </button>
        </div>
      </Modal>
    </AppBar>
  );
}
