import {
  Button,
  CircularProgress,
  Grid,
  Input,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { trackAddress } from "../Pages/TradeDex/state/tokenInfo/action";
import { getOwnToken_wallet } from "../PooCoin";
import {
  checkLocalTokenInfo,
  removeLocalTokenInfo,
  storeLocalMultichart,
  storeLocalTokenInfo,
} from "../PooCoin/util";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    borderColor: "#0f1a1c",
    textAlignLast: "center",
  },
  body: {
    padding: 0,
    paddingLeft: 10,
    color: "#fff",
    backgroundColor: "rgba(255,255,255,0.1)",
    borderColor: "#0f1a1c",
    textAlignLast: "center",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 100,
    fontSize: "0.875rem",
    padding: "10px !important",
    color: "#fff",
  },
  tableTh: {
    padding: 0,
    fontSize: "0.8125rem",
    paddingLeft: 10,
    backgroundColor: "#0f1a1c",
  },
  tableHead: { padding: "10px" },
  modalLeft: {
    textAlign: "left",
    color: "#A4FF66 !important",
    cursor: "pointer",
  },
  modalRight: {
    textAlign: "right",
    cursor: "pointer",
    color: "#A4FF66 !important",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#0f1a1c",
  },
  paper: {
    backgroundColor: "white",
    border: "none",
    padding: "40px 30px 30px 30px",
    display: "grid",
    borderRadius: "8px",
    textAlign: "center",
  },
  CircularProgress: {
    color: "#b2b5be",
    marginTop: "20px",
  },
  starredIcon: {
    cursor: "pointer",
  },
  starredFillIcon: {
    color: "#f7b500!important",
    cursor: "pointer",
  },
  tokenList: {
    cursor: "pointer",
  },
});

export default function CustomizedTables(props) {
  const classes = useStyles();
  const [open, setModalOpen] = useState(false);
  // const { account } = useWallet();
  const [walletOwnTokens, setWalletOwnToken] = useState();
  const [loading, setLoading] = useState(true);
  const [reload, setReloading] = useState(1);
  const [trackAddress1, setTrackAddress1] = useState(
    localStorage.getItem("trackWallet")
  );
  const dispatch = useDispatch();
  // const dispatchTokenInfo = (tokenAddress) => () => {
  //   dispatch({ type: "SET_TOKENADDRESS", payload: tokenAddress });
  // };
  const modalClose = () => {
    setModalOpen(false);
  };

  const loadToken = () => {
    if (!trackAddress1) return;
    getOwnToken_wallet(trackAddress1, setWalletOwnTokenData);
  };

  useEffect(() => {
    if (!trackAddress1) return;
    getOwnToken_wallet(trackAddress1, setWalletOwnTokenData);
  }, []);

  useEffect(() => {
    if (!trackAddress1) return;
    getOwnToken_wallet(trackAddress1, setWalletOwnTokenData);
  }, [trackAddress1]);

  const setWalletOwnTokenData = (data) => {
    if (data.length == 0) {
      setLoading(true);
    } else {
      setLoading(false);
      setWalletOwnToken(data);
    }
  };

  const modalOpen = () => {
    setModalOpen(true);
  };
  const addMultichartInfo = (tokenAddress) => () => {
    storeLocalMultichart(tokenAddress);
    props.onSymbol();
  };

  const reloadComponent = () => {
    reload == 1 ? setReloading(0) : setReloading(1);
  };

  const addWalletTokenData = (walletTokenData) => () => {
    checkLocalTokenInfo(walletTokenData.currency.address)
      ? removeLocalTokenInfo(walletTokenData.currency.address)
      : storeLocalTokenInfo(
          walletTokenData.currency.address,
          walletTokenData.currency.symbol,
          walletTokenData.value
        );
    reloadComponent();
  };
  const addTrackAddress = () => {
    if (!trackAddress1) return;
    dispatch(trackAddress({ address: trackAddress1 }));
    // dispatch({ type: "SET_TRACK_ADDRESS", payload: trackAddress1 });
    localStorage.setItem("trackWallet", trackAddress1);
    setModalOpen(false);
  };
  return (
    <div>
      <Grid container className={classes.tableHead}>
        <Grid item xs={6}>
          <div onClick={modalOpen} className={classes.modalLeft}>
            Track another wallet
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.modalRight} onClick={loadToken}>
            Load new tokens
          </div>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.tableTh}>
                Tokens
              </StyledTableCell>
              <StyledTableCell className={classes.tableTh}>
                Balance
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {walletOwnTokens != undefined &&
              walletOwnTokens
                .sort((a, b) => b.rateAmount - a.rateAmount)
                .map((walletOwnToken, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell
                      className={classes.tokenList}
                      component="th"
                      scope="row"
                      onClick={addMultichartInfo(
                        walletOwnToken.currency.address
                      )}
                    >
                      {walletOwnToken.currency.symbol}&nbsp;
                      <span className={"textSuccess"}>
                        ${walletOwnToken.value}
                      </span>
                      <br />
                      <span className={"textMuted"}>
                        {walletOwnToken.currency.symbol}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <span>{walletOwnToken.value}</span>
                      <br />
                      <span className={"textSuccess"}>
                        ${walletOwnToken.rateAmount}
                      </span>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal className={classes.modal} open={open} onClose={modalClose}>
        <div className={classes.paper}>
          <h2>Track Address</h2>
          <div className="in_sec">
            <Input
              placeholder="Address"
              variant="outlined"
              color="primary"
              onChange={(e) => setTrackAddress1(e.target.value)}
            />
            <Button variant="ccontaint" onClick={() => addTrackAddress()}>
              Submit
            </Button>
          </div>
        </div>
      </Modal>
      {loading && (
        <CircularProgress size={20} className={classes.CircularProgress} />
      )}
    </div>
  );
}
