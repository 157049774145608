import { Box } from "@material-ui/core";
import React from "react";
import CurrencyLogo from "../CurrencyLogo/CurrencyLogo";

export default function DoubleCurrencyLogo({
  currency0,
  currency1,
  size = 20,
  margin,
}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      mr={margin ? "0px" : `${(size / 3 + 8).toString()}px`}
    >
      {currency0 && (
        <CurrencyLogo currency={currency0} size={`${size.toString()}px`} />
      )}
      {currency1 && (
        <CurrencyLogo currency={currency1} size={`${size.toString()}px`} />
      )}
    </Box>
  );
}
