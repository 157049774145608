export const networkValue = {
  Binance: 1,
  Polygon: 137,
  Kuchain: 321,
  Ethereum: 1,
  Fantom: 250,
};

export const connectType = {
  metamask: 1,
  walletconnect: 2,
  binance: 3,
};

export const connectWalletStatus = {
  connect: "1",
  disconnect: "2",
};

export const coin = {
  USD: "USD",
  BNB: "BNB",
  WBNB: "WBNB",
  BUSD: "BUSD",
  ETH: "ETH",
  FTM: "FTM",
  USDC: "USDC",
};

// export const APIURL = "https://myreview.website/Moonchan/TRADEAPI";
export const APIURL = "https://charts.moonchan.io/TRADEAPI";

export const serverUrl = "https://charts.moonchan.io:8443/" 
  // process.env.NODE_ENV === "production" ? "https://charts.moonchan.io:8443/" : "http://localhost:4040/";
