import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import PropTypes from "prop-types";
import React from "react";
import Buyers from "./buyers";
import Sellers from "./sellers";
import Token from "./token";
import Wallet from "./wallet";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(255,255,255,0.1)",
    padding: 0,
  },
  tabTilteLength: {
    minWidth: "0px !important",
    padding: 8,
    textTransform: "none",
    color: "#fff",
  },
  tabpanel: {
    padding: "0px !important",
  },
  tabName: {
    float: "left",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function CenteredTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        className={classes.tabName}
      >
        <Tab label="Token tx" className={classes.tabTilteLength} />
        <Tab label="Wallet tx" className={classes.tabTilteLength} />
        <Tab label="Buyers" className={classes.tabTilteLength} />
        <Tab label="Sellers" className={classes.tabTilteLength} />
      </Tabs>
      <TabPanel value={value} index={0} className={classes.tabpanel}>
        <Token tokenPrice={props.tokenPrice} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Wallet />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Buyers />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Sellers />
      </TabPanel>
    </div>
  );
}
