import { TokenAmount } from "moonchan-sdk";
import { useContext, useMemo } from "react";

import { useTokenContract } from "../hooks/useContract";
import { useSingleCallResult } from "../state/multicall/hooks";
import { AppContext } from "../utils/Utils";

export function useTokenAllowance(token, owner, spender) {
  const { account, signer } = useContext(AppContext);

  const contract = useTokenContract(token?.address, false);

  const inputs = useMemo(() => [owner, spender], [owner, spender]);

  const allowance = useSingleCallResult(contract, "allowance", inputs).result;

  return useMemo(
    () =>
      token && allowance
        ? new TokenAmount(token, allowance.toString())
        : undefined,
    [token, allowance]
  );
}

export default useTokenAllowance;
