import {
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React from "react";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#0f1a1c",
    color: theme.palette.common.white,
    padding: 0,
    paddingLeft: 10,
    textAlignLast: "center",
    borderColor: "#0f1a1c",
  },
  body: {
    fontSize: "0.875rem",
    padding: 0,
    paddingLeft: 10,
    maxWidth: 100,
    backgroundColor: "rgba(255,255,255,0.1)",
    color: "#fff",
    textAlignLast: "center",
    borderColor: "#0f1a1c",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const rows = Array.from(Array(1).keys()).map((item) => {
  return {};
});
const useStyles = makeStyles({
  tableHead: { padding: "10px" },
  modalLeft: {
    textAlign: "left",
    color: "#A4FF66 !important",
    cursor: "pointer",
  },
  modalRight: {
    textAlign: "right",
    cursor: "pointer",
    color: "#A4FF66 !important",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#0f1a1c",
  },
  paper: {
    backgroundColor: "white",
    border: "none",
    padding: "40px 30px 30px 30px",
    display: "grid",
    borderRadius: "8px",
    textAlign: "center",
  },
});

export default function CustomizedTables() {
  const classes = useStyles();
  const [open, setModalOpen] = React.useState(false);

  const modalClose = () => {
    setModalOpen(false);
  };

  const modalOpen = () => {
    setModalOpen(true);
  };
  return (
    <div>
      <Grid container className={classes.tableHead}>
        <Grid item xs={12}>
          <div className={classes.modalRight}>Load new tokens</div>
        </Grid>
      </Grid>
      <TableContainer>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Tokens</StyledTableCell>
              <StyledTableCell>Balance</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody></TableBody>
        </Table>
      </TableContainer>
      <Modal className={classes.modal} open={open} onClose={modalClose}>
        <div className={classes.paper}>
          <h1>Premium required</h1>
          <p>This features requires premium tier 1.</p>
          <p>
            Unlock this premium tier by holding $100 worth of{" "}
            <a
              className={"textBlue fs3"}
              rel="noreferrer"
              target="_blank"
              href="https://app.sushi.com/add/ETH/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
            >
              Moonchan
            </a>
          </p>
          <p>
            (approximately Infinity Moonchan created from Infinity{" "}
            <a
              className={"textBlue fs3"}
              rel="noreferrer"
              target="_blank"
              href="https://app.uniswap.org/#/swap?inputCurrency=0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2&outputCurrency=0x0f304557d7f410385b45f3ce104b8f215126396d"
            >
              Moonchan
            </a>
            ) tokens in your wallet.
          </p>
        </div>
      </Modal>
    </div>
  );
}
