/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { ZoomOutMapOutlined } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";
import { getLpinfo } from "../actions";
import Lefttab from "../Component/about/tab";
import Chart2 from "../Component/basic/chart";
import Tab from "../Component/basic/hometab";
import Input from "../Component/basic/input";
import TableTab from "../Component/home/centercontain/tabletab";
import Switch from "../Component/multichart/switch";
import TokenSelect from "../Component/TokenSelect";
import DefaultTokens from "../config/default_tokens.json";
import Buttonicon from "../Images/bscscan.png";
import logo from "../Images/TokenIcons/okapi.png";
import { getAmountsOut } from "../PooCoin";
import Trade from "./Trade";
import { tokenAddress } from "./TradeDex/state/tokenInfo/action";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: "1px",
    },
    backgroundColor: "#0f1a1c!important",
    color: "black",
  },
  input: {
    display: "none",
  },
  rightTitle: {
    color: "#ffffff",
    paddingBottom: 10,
  },
  inputField: {
    width: "100%",
    marginTop: "20px",
  },
  tabContainer: {
    minHeight: "700px !important",
  },
  centerContainer: {
    padding: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "440px",
    },
  },
  leftSide: {
    padding: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  rightSide: {
    backgroundColor: "rgba(255,255,255,0.1)",
    marginTop: 5,
    padding: 10,
  },
  tokenImg: {
    width: 32,
    height: 32,
    marginTop: "4px",
    "& img[alt=img]": {
      color: "#0f1a1c",
    },
  },
  imgIcon: {
    width: "100%",
    height: "100%",
  },
  buttongrid: {
    textAlign: "-webkit-right",
  },
  button: {
    backgroundColor: "#303032",
    border: "1px solid #303032",
    color: "#f8f9fa !important",
    height: 35,
    marginLeft: 3,
    fontSize: ".875rem",
    fontWeight: 400,
    boxShadow:
      "inset 0 1px 0 hsl(0deg 0% 100% / 15%), 0 1px 1px rgb(0 0 0 / 8%)",
  },
  selectBox: {
    color: "rgb(51,51,51)",
    fontSize: 14,
    width: 230,
    marginLeft: 7,
    zIndex: 999,
    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
  },
  selecttool: {
    marginTop: 15,
    flexFlow: "row",
    [theme.breakpoints.down("xs")]: {
      flexFlow: "wrap",
    },
  },
  TokenSelect: {
    backgroundColor: "white",
    marginLeft: 10,
  },
  headerContainer: {
    height: "auto",
  },
  chartPan: {
    display: "inline-block",
    width: "100%",
  },
  contentHeader: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10px",
    },
  },
}));

export default function Tokens(props) {
  const classes = useStyles();
  const [showMode, setShowMode] = useState(1);
  const [priceRateData, setPriceRateData] = useState(0);
  const [coinpriceRateData, setCoinpriceRateData] = useState(0);
  const history = useHistory();
  const [lpDatas, setLpDatas] = useState([]);
  const [currentTokenInfo, setCurrentTokenInfo] = useState({});
  const [showTrade, setShowTrade] = useState(false);
  const [copied, setCopied] = useState(false);
  const [selectData, setSelectData] = useState([]);
  const [coinAddress, setCoinAddress] = useState();
  const [firstCoinName, setFirstCoinName] = useState();
  const [seed, setSeed] = useState(1);
  const tokenAddress1 = useSelector((state) => state.tokenInfo.tokenAddress);

  console.log(tokenAddress1, "address1");
  const dispatch = useDispatch();

  useEffect(() => {
    setCopied(false);
    dispatch(tokenAddress({ address: props.match.params.id }));
    // dispatch({ type: "SET_TOKENADDRESS", payload: props.match.params.id });
    //Get all info about current token from lpaddress and token table
    if (tokenAddress1 != undefined) {
      getLpinfo(tokenAddress1).then((data) => {
        if (
          data.lpInfos == null ||
          data.tokenInfos == null ||
          data.lpInfos.length == 0 ||
          data.tokenInfos.length == 0
        ) {
          setFirstCoinName(null);
          setCoinAddress(null);
          setLpDatas(null);
          setSelectData(null);
          setCurrentTokenInfo(null);
          return;
        }
        const tokens = [];
        const selectOptionData = [];
        for (var idx in data.lpInfos) {
          if (data.lpInfos[idx].token0 == data.tokenInfos.address) {
            let combined_json = {};
            combined_json["label"] = data.lpInfos[idx].tokenName1;
            //0: other token address, 1: other token symbolName 2: lp address 3: token order
            let addrLpInfo = [
              data.lpInfos[idx].token1,
              data.lpInfos[idx].tokenSymbol1,
              data.lpInfos[idx].lp_address,
              0,
            ];
            combined_json["value"] = addrLpInfo;
            tokens.push(combined_json);

            let selectdata_json = {};
            selectdata_json["label"] =
              "Uniswap v2 " +
              data.lpInfos[idx].tokenSymbol0 +
              "/" +
              data.lpInfos[idx].tokenSymbol1;
            selectdata_json["value"] = data.lpInfos[idx].token1;
            selectOptionData.push(selectdata_json);
          } else {
            let combined_json = {};
            combined_json["label"] = data.lpInfos[idx].tokenName0;
            let addrLpInfo = [
              data.lpInfos[idx].token0,
              data.lpInfos[idx].tokenSymbol0,
              data.lpInfos[idx].lp_address,
              1,
            ];
            combined_json["value"] = addrLpInfo;
            tokens.push(combined_json);

            let selectdata_json = {};
            selectdata_json["label"] =
              "Uniswap v2 " +
              data.lpInfos[idx].tokenSymbol1 +
              "/" +
              data.lpInfos[idx].tokenSymbol0;
            selectdata_json["value"] = data.lpInfos[idx].token0;
            selectOptionData.push(selectdata_json);
          }
        }

        if (tokens[0].value[1] == DefaultTokens.WBNB.symbol) {
          setFirstCoinName(DefaultTokens.BNB.symbol);
        } else {
          setFirstCoinName(tokens[0].value[1]);
        }

        setCoinAddress(tokens[0].value[0]);
        setLpDatas(tokens);
        setSelectData(selectOptionData);

        setCurrentTokenInfo(data.tokenInfos);
        //Get Lpaddress from current token address and BUSD token address
        getAmountsOut(1, tokenAddress1, tokens[0].value[0], setPriceRateData);
        getAmountsOut(
          1,
          tokens[0].value[0],
          DefaultTokens.USDT.address,
          setCoinpriceRateData
        );
      });
    }
  }, [tokenAddress1]);
  const handleChange = () => {
    setShowMode(!showMode);
  };
  const handleChangeLeft = () => {
    setShowMode(!showMode);
  };

  const handleTokenPropsChange = (tokenInfo) => {
    const tokenAddress1 = tokenInfo.address;
    history.push(`/tokens/${tokenAddress1}`);
    dispatch(tokenAddress(tokenAddress1));
    // dispatch({ type: "SET_TOKENADDRESS", payload: tokenAddress1 });
  };

  const inputHandle = (tokenadd) => {
    history.push(`/tokens/${tokenadd}`);
    dispatch(tokenAddress(tokenadd));
    // dispatch({ type: "SET_TOKENADDRESS", payload: tokenadd });
  };

  const reload = () => {
    setSeed(Math.random());
  };

  const [tradeContent, setTradeContent] = useState();
  const handleTrade = () => {
    if (showTrade) {
      setTradeContent();
      setShowTrade(false);
    } else {
      setTradeContent(<Trade />);
      setShowTrade(true);
    }
  };
  const tokenSelect = (event) => {
    setCoinAddress(event.value);
  };
  let centerContainer = (
    <div>
      <div className={classes.headerContainer}>
        <Grid container>
          <Grid xs={6} md={6} sm={6} item className={classes.contentHeader}>
            <Grid container>
              <Grid item xl={12}>
                <p
                  style={{
                    display: "flex",
                    color: "white",
                    textAlign: "left",
                    margin: 0,
                    float: "left",
                  }}
                >
                  <span className={classes.tokenImg}>
                    {currentTokenInfo != null && (
                      <img
                        className={classes.imgIcon}
                        src={
                          currentTokenInfo.symbol == undefined
                            ? logo
                            : `https://coinicons-api.vercel.app/api/icon/${currentTokenInfo.symbol.toLowerCase()}`
                        }
                        alt="img"
                      />
                    )}
                  </span>
                  <span style={{ paddingLeft: "10px" }}>
                    {currentTokenInfo == null ? null : currentTokenInfo.name} (
                    {currentTokenInfo == null ? null : currentTokenInfo.name}/
                    {firstCoinName})
                    <br />
                    <span className={"textSuccess"}>
                      $
                      {parseFloat(priceRateData * coinpriceRateData).toFixed(6)}
                    </span>
                  </span>
                </p>
              </Grid>
              <Grid xl={12} style={{ float: "left" }}>
                <Box
                  sx={{
                    display: "flex",
                    columnGap: "10px",
                    alignItems: "center",
                  }}
                >
                  <TokenSelect
                    inputHandle={inputHandle}
                    tokenProps={handleTokenPropsChange}
                  />
                  {copied ? (
                    <Box
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: "8px",
                      }}
                    >
                      <CheckIcon
                        style={{
                          color: "#a4ff66",
                          fontSize: "1.5rem",
                          margin: "6px",
                        }}
                      />
                    </Box>
                  ) : (
                    <CopyToClipboard
                      text={tokenAddress1}
                      onCopy={() => setCopied(true)}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "8px",
                          cursor: "pointer",
                          zIndex:1
                        }}
                      >
                        <FileCopyIcon
                          style={{
                            color: "#000",
                            fontSize: "1.5rem",
                            margin: "6px",
                          }}
                        />
                      </Box>
                    </CopyToClipboard>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid md={6} sm={6} item className={classes.buttongrid}>
            <div>
              <Button
                className={classes.button}
                target="_blank"
                rel="noreferrer"
                href={`https://etherscan.io/token/${tokenAddress1}`}
              >
                <img src={Buttonicon} width="18" height="18" />
              </Button>
              {/*<Button className={classes.button} onClick={handleTrade}>Trade</Button>*/}
              <Button className={classes.button} onClick={handleChange}>
                <ZoomOutMapOutlined />
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.selecttool}>
          <Button className={classes.button} onClick={() => reload()}>
            Reload
          </Button>
          <div className={classes.selectBox}>
            <Select
              options={selectData}
              // input={false}
              onChange={tokenSelect}
              // onInputChange={tokenInputChange}
            ></Select>
          </div>
          <Switch />
        </Grid>
      </div>
      <Grid xs={12} style={{ marginTop: 20 }} item>
        <div>{tradeContent}</div>
        <div className={classes.chartPan}>
          {coinAddress != undefined && (
            <Chart2
              key={seed}
              tokenAddress={tokenAddress1}
              coinAddress={coinAddress}
              height="500px"
            />
          )}
        </div>
        <br />
        {/* {
          lpDatas != null && */}
        <TableTab tokenPrice={priceRateData * coinpriceRateData} />
        {/* } */}
      </Grid>
    </div>
  );

  let container;

  if (showMode) {
    container = (
      <Grid container>
        <Grid item xs={12} sm={3} md={3} className={classes.leftSide}>
          {/* {
            currentTokenInfo != null && */}
          <Lefttab
            lpdata={lpDatas}
            currentTokenInfo={currentTokenInfo}
            priceRateData={priceRateData * coinpriceRateData}
          />
          {/* } */}
        </Grid>
        <Grid item xs={12} sm={9} md={6} className={classes.centerContainer}>
          {centerContainer}
        </Grid>
        <Grid item xs={12} sm={12} md={3} className={classes.rightSide}>
          <div className={classes.rightTitle}>Sponsored ETH Project</div>
          <Input className={classes.inputField} />
          <Tab className={classes.tabContainer} />
        </Grid>
      </Grid>
    );
  } else {
    container = (
      <Grid container item xs={12} md={12} sm={12} xl={12}>
        <Grid item xs={12} md={3} sm={3} xl={3} className={classes.leftSide}>
          {/* {
            currentTokenInfo != null && */}
          <Lefttab
            lpdata={lpDatas}
            currentTokenInfo={currentTokenInfo}
            priceRateData={priceRateData * coinpriceRateData}
          />
          {/* } */}
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
          sm={9}
          xl={9}
          className={classes.centerContainer}
        >
          {centerContainer}
        </Grid>
      </Grid>
    );
  }

  return <Grid className={classes.root}>{container}</Grid>;
}
