/* eslint-disable jsx-a11y/alt-text */
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";
import { getLpinfo } from "../actions";
import Lefttab from "../Component/about/tab";
import Chart2 from "../Component/basic/chart";
import TableTab from "../Component/home/centercontain/tabletab";
import Switch from "../Component/multichart/switch";
import TokenSelect from "../Component/TokenSelect";
import DefaultTokens from "../config/default_tokens.json";
import Buttonicon from "../Images/bscscan.png";
import PoocoinIcon from "../Images/poocoin512.png";
import logo from "../Images/TokenIcons/okapi.png";
import { getAmountsOut } from "../PooCoin";
import { tokenAddress } from "./TradeDex/state/tokenInfo/action";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: "1px",
      padding: "0px 30px 0px 0px",
      color: "black",
    },
    textAlign: "-webkit-center",
    [theme.breakpoints.down("xs")]: {
      "& > *": {
        padding: "0px 00px 0px 0px",
      },
    },
  },
  headerContent: {
    color: "#fff",
    padding: "20px",
  },
  input: {
    display: "none",
  },
  inputField: {
    width: "100%",
    marginTop: "20px",
  },
  tabContainer: {
    minHeight: "700px !important",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  buttongrid: {
    textAlign: "-webkit-right",
  },
  button: {
    backgroundColor: "#303032",
    border: "1px solid #303032",
    color: "#f8f9fa !important",
    height: 35,
    marginLeft: 3,
    fontSize: ".875rem",
    fontWeight: 400,
    boxShadow:
      "inset 0 1px 0 hsl(0deg 0% 100% / 15%), 0 1px 1px rgb(0 0 0 / 8%)",
  },
  selectBox: {
    color: "rgb(51,51,51)",
    fontSize: 14,
    width: 230,
    marginLeft: 7,
    zIndex: 999,
    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
  },
  selecttool: {
    marginTop: 15,
    flexFlow: "row",
    [theme.breakpoints.down("xs")]: {
      flexFlow: "wrap",
    },
  },
  tokenSelect: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
    },
  },
  headerContainer: {
    height: "auto",
  },
  chartPan: {
    display: "inline-block",
    width: "100%",
  },
  centerContainer: {
    paddingTop: "20px",
    maxWidth: "1140px",
  },
  title: {
    // color: '#fff',
    fontSize: "2.5rem",
    fontWeight: 700,
    fontFamily: '"Lato",sans-serif',
    // marginTop: '20px'
  },
  buyBtn: {
    marginLeft: "48px",
    backgroundColor: "#A4FF67",
    fontSize: "20px",
  },
  subDesc: {
    // color: '#f8f9fa',
    fontSize: "18px",
    marginBottom: "8px",
    textAlign: "-webkit-center",
  },
  border: {
    border: "2px solid #f8f9fa",
    borderRadius: "999px",
    // boxShadow: '0.5rem 1rem rgba(0,0,0,.15)!important',
    marginBottom: "10px",
    width: "30%",
    minWidth: 286,
    textAlign: "-webkit-center",
    fontFamily: '"Lato",sans-serif',
  },
  poocoinImg: {
    padding: 5,
  },
  value: {
    fontSize: "2rem",
    marginLeft: "1rem",
    marginRight: "1.5rem",
    padding: "31px 0",
  },
  link: {
    marginTop: "0px",
    "& a": {
      color: "#A4FF66 !important",
      fontSize: "17px !important",
      marginBottom: "8px !important",
      fontWeight: 500,
      fontFamily: "Lato, Sans-serif",
    },
  },
  desc: {
    // color: '#f8f9fa',
    fontSize: "20px",
    lineHeight: 1.2,
    paddingTop: 10,
    fontWeight: 500,
    fontFamily: '"Lato",sans-serif',
    marginBottom: "8px",
  },
  leftSide: {
    padding: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

export default function About(props) {
  const classes = useStyles();
  const [showMode, setShowMode] = useState(1);
  const [priceRateData, setPriceRateData] = useState(0);
  const history = useHistory();
  const [lpDatas, setLpDatas] = useState([]);
  const [currentTokenInfo, setCurrentTokenInfo] = useState({});
  const [selectData, setSelectData] = useState([]);
  const [coinAddress, setCoinAddress] = useState(DefaultTokens.WBNB.address);
  const [priceData, setPriceData] = useState([]);
  const [coinpriceRateData, setCoinpriceRateData] = useState(0);
  const [key, setKey] = useState(1);
  // const tokenAddress = useSelector((state) => state.tokenInfo.tokenAddress)
  const dispatch = useDispatch();
  let tokenGet = props.tokenAddress;

  useEffect(() => {
    dispatch(tokenAddress({ address: tokenGet }));
    // dispatch({ type: "SET_TOKENADDRESS", payload: props.tokenAddress });
    //Get all info about current token from lpaddress and token table
    getLpinfo(tokenGet).then((data) => {
      const tokens = [];
      const selectOptionData = [];
      for (var idx in data.lpInfos) {
        if (data.lpInfos[idx].token0 == tokenGet) {
          let combined_json = {};
          combined_json["label"] = data.lpInfos[idx].tokenName1;
          //0: other token address, 1: other token symbolName 2: lp address 3: token order
          let addrLpInfo = [
            data.lpInfos[idx].token1,
            data.lpInfos[idx]?.tokenSymbol1,
            data.lpInfos[idx].lp_address,
            0,
          ];
          combined_json["value"] = addrLpInfo;
          tokens.push(combined_json);

          let selectdata_json = {};
          selectdata_json["label"] =
            "Uniswap " +
            data.lpInfos[idx]?.tokenSymbol0 +
            "/" +
            data.lpInfos[idx]?.tokenSymbol1;
          selectdata_json["value"] = data.lpInfos[idx].token1;
          selectOptionData.push(selectdata_json);
        } else {
          let combined_json = {};
          combined_json["label"] = data.lpInfos[idx].tokenName0;
          let addrLpInfo = [
            data.lpInfos[idx].token0,
            data.lpInfos[idx]?.tokenSymbol0,
            data.lpInfos[idx].lp_address,
            1,
          ];
          combined_json["value"] = addrLpInfo;
          tokens.push(combined_json);

          let selectdata_json = {};
          selectdata_json["label"] =
            "Uniswap " +
            data.lpInfos[idx]?.tokenSymbol1 +
            "/" +
            data.lpInfos[idx]?.tokenSymbol0;
          selectdata_json["value"] = data.lpInfos[idx].token0;
          selectOptionData.push(selectdata_json);
        }
      }
      setLpDatas(tokens);
      setSelectData(selectOptionData);
      setCurrentTokenInfo(data.tokenInfos);
      getAmountsOut(1, tokenGet, tokens[0].value[0], setPriceData);
      getAmountsOut(
        1,
        tokens[0].value[0],
        DefaultTokens.USDT.address,
        setCoinpriceRateData
      );
    });
    //Get Lpaddress from current token address and BUSD token address
  }, [tokenGet]);

  const handleTokenPropsChange = (tokenInfo) => {
    const tokenAddress1 = tokenInfo.address;
    history.push(`/tokens/${tokenAddress1}`);
    dispatch(tokenAddress({ address: tokenAddress1 }));
    // dispatch({ type: "SET_TOKENADDRESS", payload: tokenAddress });
  };

  const inputHandle = (tokenAddress1) => {
    history.push(`/tokens/${tokenAddress1}`);
    dispatch(tokenAddress({ address: tokenAddress1 }));
    // dispatch({ type: "SET_TOKENADDRESS", payload: tokenAddress });
  };

  const tokenSelect = (event) => {
    setCoinAddress(event.value);
  };

  const reload = () => {
    setKey(Math.random());
  };

  let centerContainer = (
    <div>
      <div className={classes.headerContainer}>
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} md={6} xl={6} item>
            <p
              style={{
                display: "flex",
                color: "white",
                textAlign: "left",
                margin: 0,
                float: "left",
                paddingLeft: "5px",
              }}
            >
              <img
                className={classes.img}
                src={
                  currentTokenInfo?.symbol == undefined
                    ? logo
                    : `https://coinicons-api.vercel.app/api/icon/${currentTokenInfo?.symbol.toLowerCase()}`
                }
                width="32"
                height="32"
              />
              <span style={{ paddingLeft: "10px" }}>
                {currentTokenInfo?.name} ({currentTokenInfo?.name}/WETH)
                <br />
                <span className={"textSuccess"}>
                  ${parseFloat(priceData * coinpriceRateData).toFixed(6)}
                </span>
              </span>
            </p>
            <div className={classes.tokenSelect} style={{ float: "left" }}>
              <TokenSelect
                inputHandle={inputHandle}
                tokenProps={handleTokenPropsChange}
              />
            </div>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={6}
            xl={6}
            item
            className={classes.buttongrid}
          >
            <div>
              <Button
                className={classes.button}
                target="_blank"
                rel="noreferrer"
                href={`https://etherscan.io/token/${tokenGet}`}
              >
                <img src={Buttonicon} width="18" height="18" />
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid item container spacing={2} xs={12} className={classes.selecttool}>
          <Button className={classes.button} onClick={() => reload()}>
            Reload
          </Button>
          <div className={classes.selectBox}>
            <Select
              options={selectData}
              // input={false}
              onChange={tokenSelect}
              // onInputChange={tokenInputChange}
            ></Select>
          </div>
          <Switch />
        </Grid>
      </div>
      <Grid xs={12} style={{ marginTop: 20, padding: 5 }} item>
        <div className={classes.chartPan}>
          <Chart2
            key={key}
            tokenAddress={tokenGet}
            coinAddress={coinAddress}
            height="500px"
          />
        </div>
        <br />
        <TableTab tokenPrice={priceRateData} />
      </Grid>
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.headerContent}>
        <div className={classes.title}>
          Moon Chan
          <Button variant="contained" className={classes.buyBtn} href={"#"}>
            Buy
          </Button>
        </div>
        <br></br>
        {/* <div className={classes.subDesc}>Set slippage to 9% on uniswap.</div> */}
        <div className={classes.subDesc}>
          Taxes 5/5, 2% Lp, 2% Marketing, 1% Team/Development
        </div>
        <br></br>
        <div className={classes.subDesc}>
          <div className={classes.border} container>
            <Grid container>
              <span
                style={{ borderRadius: "999px", margin: "20px 10px 20px 10px" }}
              >
                <img
                  src={PoocoinIcon}
                  height="70"
                  className={classes.poocoinImg}
                />
              </span>
              <span className={classes.value}>
                {" "}
                ${parseFloat(priceData * coinpriceRateData).toFixed(6)}
              </span>
            </Grid>
          </div>
        </div>
        <div className={classes.desc}>
          MoonChan token on Ethereum Blockchain.
        </div>
        {/* <div className={classes.desc}>A 8% fee is charged on each transaction. 4% is distributed to other token holders and 4% is burned.</div> */}
        <div className={classes.desc}>
          All revenue from ad space is bought back into the MoonChan, <br></br>{" "}
          Owning MoonChan token is owning a piece of MoonChans tools.
        </div>
        <div className={classes.desc}>
          Stake your moonchan for mouth watering APY. 30 day locks
          earn you 150% apy!
        </div>
        {/* <div className={classes.desc}>Its 3% buy 3% sell. 1.5% to marketing, 1.5% to donation to Okapis</div> */}
        <br></br>
        {/*<div className={classes.link}><a target="_blank" href="#" className={classes.linkName}>Mediumm</a></div>
        <div className={classes.link}><a target="_blank" href="#" className={classes.linkName}>Twitter</a></div>
        <div className={classes.link}><a target="_blank" href="#" className={classes.linkName}>Telegram</a></div>
        <div className={classes.link}><a target="_blank" href="#" className={classes.linkName}>Reddit</a></div>
  <div className={classes.link}><a target="_blank" href="#" className={classes.linkName}>Discord</a></div>*/}
      </div>
      <div className={classes.centerContainer}>
        <Grid container item xs={12}>
          <Grid item xs={12} md={4} sm={4} xl={4} className={classes.leftSide}>
            <Lefttab
              lpdata={lpDatas}
              currentTokenInfo={currentTokenInfo}
              priceRateData={priceData * coinpriceRateData}
            />
          </Grid>
          <Grid item xs={12} md={8} sm={8} xl={8}>
            {centerContainer}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
