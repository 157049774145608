import { ChainId, JSBI, Percent, Token, WETH } from "moonchan-sdk";

export const ROUTER_ADDRESS = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";

// export const WBNB = new Token(
//   ChainId.BSCTESTNET,
//   "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
//   18,
//   "WETH",
//   "Wrapped ETH"
// );
// export const BUSD = new Token(
//   ChainId.BSCTESTNET,
//   "0x37c23Dd161989d7BeE72dd6Cc49b83b2B884Cb4D",
//   18,
//   "BUSD",
//   "Binance USD"
// );
// export const BUB = new Token(
//   ChainId.BSCTESTNET,
//   "0xBF96727212E3c7070D9A7db6004bC511031FF9c5",
//   9,
//   "BUB",
//   "Bubble Token"
// );
// export const USDT = new Token(
//   ChainId.BSCTESTNET,
//   "0xCe3184CD62c5a7B4bDc178C0B06C336762f98C8a",
//   6,
//   "USDT ",
//   "Tether USD"
// );

//mainnet
export const SHIB = new Token(
  ChainId.MAINNET,
  "0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE",
  18,
  "SHIB",
  "SHIBA INU"
);
export const WBNB = new Token(
  ChainId.MAINNET,
  "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  18,
  "WBNB",
  "Wrapped BNB"
);
export const MC = new Token(
  ChainId.MAINNET,
  "0x0f304557d7F410385B45f3ce104B8f215126396D",
  18,
  "MC",
  "MoonChan"
);
export const USDT = new Token(
  ChainId.MAINNET,
  "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  6,
  "USDT",
  "Tether USD"
);
// export const DAI = new Token(
//   ChainId.MAINNET,
//   "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
//   18,
//   "DAI",
//   "DAIcoin"
// );

// export const USDC = new Token(
//   ChainId.MAINNET,
//   "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
//   18,
//   "USDC",
//   "USD Coin"
// );
// export const PAY = new Token(
//   ChainId.MAINNET,
//   "0xFeea0bDd3D07eb6FE305938878C0caDBFa169042",
//   18,
//   "8PAY",
//   "8PAY Network"
// );

// export const ACH = new Token(
//   ChainId.MAINNET,
//   "0xBc7d6B50616989655AfD682fb42743507003056D",
//   18,
//   "ACH",
//   "Alchemy Token"
// );

// export const ADA = new Token(
//   ChainId.MAINNET,
//   "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
//   18,
//   "ADA",
//   "Cardano Token"
// );

// export const ADX = new Token(
//   ChainId.MAINNET,
//   "0x6bfF4Fb161347ad7de4A625AE5aa3A1CA7077819",
//   18,
//   "ADX",
//   "AdEx Network"
// );
// export const ALICE = new Token(
//   ChainId.MAINNET,
//   "0xAC51066d7bEC65Dc4589368da368b212745d63E8",
//   18,
//   "ALICE",
//   "My Neigbor Alice"
// );

// export const ALPA = new Token(
//   ChainId.MAINNET,
//   "0xc5E6689C9c8B02be7C49912Ef19e79cF24977f03",
//   18,
//   "ALPA",
//   "AlpaToken"
// );
// export const BTCB = new Token(
//   ChainId.MAINNET,
//   "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
//   18,
//   "BTCB",
//   "BTCB Token"
// );

// export const ALPCA = new Token(
//   ChainId.MAINNET,
//   "0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F",
//   18,
//   "ALPCA",
//   "Alpaca"
// );

// export const ALPHA = new Token(
//   ChainId.MAINNET,
//   "0xa1faa113cbE53436Df28FF0aEe54275c13B40975",
//   18,
//   "ALPHA",
//   "AlphaToken"
// );

// export const AMPL = new Token(
//   ChainId.MAINNET,
//   "0xDB021b1B247fe2F1fa57e0A87C748Cc1E321F07F",
//   18,
//   "AMPL",
//   "Ampleforth"
// );
// export const APE = new Token(
//   ChainId.MAINNET,
//   "0xC762043E211571eB34f1ef377e5e8e76914962f9",
//   18,
//   "APE",
//   "ApeCoin"
// );

// export const BCH = new Token(
//   ChainId.MAINNET,
//   "0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf",
//   18,
//   "BCH",
//   "Bitcoin Cash Token"
// );

const WETH_ONLY = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.BSCTESTNET]: [WETH[ChainId.BSCTESTNET]],
};

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [
    ...WETH_ONLY[ChainId.MAINNET],
    // WBNB,
    // BUB,
    // BUSD,
    // USDT,
    SHIB,
    WBNB,
    MC,
    USDT,
    // BTCB,
    // DAI,
    // USDC,
    // PAY,
    // ACH,
    // ADA,
    // ADX,
    // ALICE,
    // ALPA,
    // ALPCA,
    // ALPHA,
    // AMPL,
    // APE,
    // BCH,
  ],
};

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES = {
  [ChainId.BSCTESTNET]: {},
};

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [
    ...WETH_ONLY[ChainId.MAINNET],
    // WBNB,
    // BUB,
    // BUSD,
    // USDT,
    SHIB,
    WBNB,
    MC,
    USDT,
    // BTCB,
    // DAI,
    // USDC,
    // PAY,
    // ACH,
    // ADA,
    // ADX,
    // ALICE,
    // ALPA,
    // ALPCA,
    // ALPHA,
    // AMPL,
    // APE,
    // BCH,
  ],
};

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [
    ...WETH_ONLY[ChainId.MAINNET],
    // WBNB,
    // BUB,
    // BUSD,
    // USDT,
    SHIB,
    WBNB,
    MC,
    USDT,
    // BTCB,
    // DAI,
    // USDC,
    // PAY,
    // ACH,
    // ADA,
    // ADX,
    // ALICE,
    // ALPA,
    // ALPCA,
    // ALPHA,
    // AMPL,
    // APE,
    // BCH,
  ],
};

export const PINNED_PAIRS = {
  [ChainId.MAINNET]: [
    // [BUSD, WBNB], //Test
    // [BUSD, BUB], //Test
    // [WBNB, USDT], //Test
    // [USDT, BUSD], //Test
    [WBNB, MC],
    [WBNB, USDT],
    [WBNB, SHIB],
    // [USDC, PAY],
    // [ACH, ADA],
    // [ADX, ALICE],
    // [ALPA, ALPCA],
    // [ALPHA, AMPL],
    // [APE, BCH],
  ],
};

export const NetworkContextName = "ETHNETWORK";

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 80;
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20;

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000));
export const BIPS_BASE = JSBI.BigInt(10000);
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW = new Percent(
  JSBI.BigInt(100),
  BIPS_BASE
); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM = new Percent(
  JSBI.BigInt(300),
  BIPS_BASE
); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH = new Percent(
  JSBI.BigInt(500),
  BIPS_BASE
); // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN = new Percent(
  JSBI.BigInt(1000),
  BIPS_BASE
); // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT = new Percent(
  JSBI.BigInt(1500),
  BIPS_BASE
); // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)); // .01 ETH
