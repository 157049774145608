import { Box } from "@material-ui/core";
import rateImg from "../../../../Images/rate.png";
export default function TradePrice({ price, showInverted, setShowInverted }) {
  const formattedPrice = showInverted
    ? price?.toSignificant(6)
    : price?.invert()?.toSignificant(6);

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency);
  const label = showInverted
    ? `${price?.quoteCurrency?.symbol} per ${price?.baseCurrency?.symbol}`
    : `${price?.baseCurrency?.symbol} per ${price?.quoteCurrency?.symbol}`;

  return (
    <Box
      fontSize="12px"
      fontWeight="Light"
      color="#fff"
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        padding: "10px",
      }}
    >
      {show ? (
        <>
          {formattedPrice ?? "-"} {label}
          <Box color="#fff" onClick={() => setShowInverted(!showInverted)}>
            <img
              src={rateImg}
              alt=""
              width="15px"
              style={{ marginLeft: "5px" }}
            />
          </Box>
        </>
      ) : (
        "-"
      )}
    </Box>
  );
}
