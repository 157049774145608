import {
  CircularProgress,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import StarIcon from "@material-ui/icons/Star";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppContext } from "../../Pages/TradeDex/utils/Utils";
// import { useWallet } from "use-wallet";
import { getOwnToken_wallet } from "../../PooCoin";
import {
  checkLocalTokenInfo,
  removeLocalTokenInfo,
  storeLocalMultichart,
  storeLocalTokenInfo,
} from "../../PooCoin/util";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    borderColor: "#0f1a1c",
    textAlignLast: "center",
  },
  body: {
    padding: 0,
    paddingLeft: 10,
    color: "#fff",
    backgroundColor: "rgba(255,255,255,0.1)",
    borderColor: "#0f1a1c",
    textAlignLast: "center",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 100,
    fontSize: "0.875rem",
    padding: "10px !important",
    color: "#fff",
  },
  tableTh: {
    padding: 0,
    fontSize: "0.8125rem",
    paddingLeft: 10,
    backgroundColor: "#0f1a1c",
  },
  tableHead: { padding: "10px" },
  modalLeft: {
    textAlign: "left",
    color: "#A4FF66 !important",
    cursor: "pointer",
  },
  modalRight: {
    textAlign: "right",
    cursor: "pointer",
    color: "#A4FF66 !important",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#0f1a1c",
  },
  paper: {
    backgroundColor: "white",
    border: "none",
    padding: "40px 30px 30px 30px",
    display: "grid",
    borderRadius: "8px",
    textAlign: "center",
  },
  CircularProgress: {
    color: "#b2b5be",
    marginTop: "20px",
  },
  starredIcon: {
    cursor: "pointer",
  },
  starredFillIcon: {
    color: "#f7b500!important",
    cursor: "pointer",
  },
  tokenList: {
    cursor: "pointer",
  },
  tableBody: {
    backgroundColor: "rgba(0,0,0.1) !important",
  },
});

export default function CustomizedTables(props) {
  const classes = useStyles();
  const [open, setModalOpen] = useState(false);
  // const { account } = useWallet();
  const { account } = useContext(AppContext);
  const [walletOwnTokens, setWalletOwnToken] = useState();
  const [loading, setLoading] = useState(true);
  const [reload, setReloading] = useState(1);
  const dispatch = useDispatch();

  // const dispatchTokenInfo = (tokenAddress) => () => {
  //   dispatch({ type: "SET_TOKENADDRESS", payload: tokenAddress });
  // };
  const modalClose = () => {
    setModalOpen(false);
  };

  const loadToken = () => {
    getOwnToken_wallet(account, setWalletOwnTokenData);
  };

  useEffect(() => {
    getOwnToken_wallet(account, setWalletOwnTokenData);
  }, []);

  const setWalletOwnTokenData = (data) => {
    if (data.length == 0) {
      setLoading(true);
    } else {
      setLoading(false);
      setWalletOwnToken(data);
    }
  };

  const modalOpen = () => {
    setModalOpen(true);
  };
  const addMultichartInfo = (tokenAddress) => () => {
    storeLocalMultichart(tokenAddress);
    props.onSymbol();
  };

  const reloadComponent = () => {
    reload == 1 ? setReloading(0) : setReloading(1);
  };

  const addWalletTokenData = (walletTokenData) => () => {
    checkLocalTokenInfo(walletTokenData.currency.address)
      ? removeLocalTokenInfo(walletTokenData.currency.address)
      : storeLocalTokenInfo(
          walletTokenData.currency.address,
          walletTokenData.currency.symbol,
          walletTokenData.value
        );
    reloadComponent();
  };

  return (
    <div>
      <Grid container className={classes.tableHead}>
        <Grid item xs={12}>
          <div className={classes.modalRight} onClick={loadToken}>
            Load new tokens
          </div>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.tableTh}>
                Tokens
              </StyledTableCell>
              <StyledTableCell className={classes.tableTh}>
                Balance
              </StyledTableCell>
              <StyledTableCell className={classes.tableTh}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {walletOwnTokens != undefined &&
              walletOwnTokens
                .sort((a, b) => b.rateAmount - a.rateAmount)
                .map((walletOwnToken, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell
                      className={classes.tokenList}
                      component="th"
                      scope="row"
                      onClick={addMultichartInfo(
                        walletOwnToken.currency.address
                      )}
                    >
                      {walletOwnToken.currency.symbol}&nbsp;
                      <span className={"textSuccess"}>
                        ${walletOwnToken.value}
                      </span>
                      <br />
                      <span className={"textMuted"}>
                        {walletOwnToken.currency.symbol}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <span>{walletOwnToken.value}</span>
                      <br />
                      <span className={"textSuccess"}>
                        ${walletOwnToken.rateAmount}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell>
                      {checkLocalTokenInfo(walletOwnToken.currency.address) ==
                      true ? (
                        <StarIcon
                          className={classes.starredFillIcon}
                          onClick={addWalletTokenData(walletOwnToken)}
                        />
                      ) : (
                        <StarOutlineIcon
                          className={classes.starredIcon}
                          onClick={addWalletTokenData(walletOwnToken)}
                        />
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal className={classes.modal} open={open} onClose={modalClose}>
        <div className={classes.paper}>
          <h1>Premium required</h1>
          <p>This features requires premium tier 1.</p>
          <p>
            Unlock this premium tier by holding $100 worth of{" "}
            <a
              className={"textBlue fs3"}
              rel="noreferrer"
              target="_blank"
              href="https://app.sushi.com/add/ETH/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
            >
              Moonchan
            </a>
          </p>
          <p>
            (approximately Infinity Moonchan created from Infinity{" "}
            <a
              className={"textBlue fs3"}
              rel="noreferrer"
              target="_blank"
              href="https://app.uniswap.org/#/swap?inputCurrency=0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2&outputCurrency=0x0f304557d7f410385b45f3ce104b8f215126396d"
            >
              Moonchan
            </a>
            ) tokens in your wallet.
          </p>
        </div>
      </Modal>
      {loading && (
        <CircularProgress size={20} className={classes.CircularProgress} />
      )}
    </div>
  );
}
