/* eslint-disable import/first */
/* eslint-disable no-loop-func */
const ethers = require("ethers");
const Web3 = require("web3");
const BigNumber = require("bignumber.js");
import erc20_abi from "../config/abi/erc20.json";
import router_abi from "../config/abi/router.json";
import DefaultTokens from "../config/default_tokens.json";
import { getOwnToken, getTransactionListData } from "./bitquery";
import { numberWithCommas } from "./util";

const topHolderAddress = "0xCD7e5AcD74125dB152Cab2B2C58a039583B49F6f";
const profileAddress = "0x1c89E89A85c80c565418261d12E30d3716641566";
const unvettedAddress = "0xb16ca392B13479f7416D1c3B03a9E0bD493b7Bc6";
const poocoinAddress = "0x0f304557d7f410385b45f3ce104b8f215126396d"; //STAKE (STAKE)  FTm
const myAccount = "0x506b102e2cea72476a41f3d4200cf3fa7a56dd3e"; //my Account
const getLogsAddress = "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f"; // factory
const topics =
  "0x0d3648bd0f6ba80134a33ba9275ac585d9d315f0ad8355cddefde31afa28d0e9";
// pancakeswap v2 router addresss
// const pancakeswap_router = "0x10ED43C718714eb63d5aA57B78B54704E256024E";
const pancakeswap_router = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D"; //sushiswap Router

const provider = new ethers.providers.EtherscanProvider(
  "homestead",
  "HEAWNHKUCE8SI4XIAJDF66C4RG2M72JZ89"
);
// const provider = new ethers.providers.WebSocketProvider(
//   "wss://bsc-ws-node.nariox.org:443"
// );
// var web3 = new Web3(
//   new Web3.providers.WebsocketProvider("wss://bsc-ws-node.nariox.org:443")
// );
var web3 = new Web3(
  new Web3.providers.HttpProvider(
    "https://mainnet.infura.io/v3/cf32c42859984d17aa6aa7711489c4a2"
  )
);

const abi = [
  "function holderInfo(uint256, uint256) public view returns (address, uint256)",
  "function topHolderSize() public view returns (uint256)",
  "function userInfo(address) public view returns (string, address, string)",

  // symbol and name of token
  "function symbol() public pure returns (string)",
  "function name() public pure returns (string)",

  //get balance
  "function balanceOf(address) public view returns (uint256)",

  // get amount out
  "function getAmountsOut(uint amountIn, address[] memory path) public view returns (uint[] memory amounts)",

  // swap
  "function swapExactTokensForTokens(uint amountIn, uint amountOutMin, address[] calldata path, address to, uint deadline) external returns (uint[] memory amounts)",
  "function getAmountIn(uint amountOut, uint reserveIn, uint reserveOut) internal pure returns (uint amountIn)",
  "function getAmountOut(uint amountIn, uint reserveIn, uint reserveOut) internal pure returns (uint amountOut)",

  // totalSupply
  "function totalSupply() public view returns (uint256)",
  //get reserve
  "function getReserves() public view returns (uint112 _reserve0, uint112 _reserve1, uint32 _blockTimestampLast) ",
];

const unvettedAbi = [
  "function messages(uint256) public view returns (address, string)",
  "function messageLength() public view returns (uint256)",
];

const contract = new ethers.Contract(topHolderAddress, abi, provider);
const profile_contract = new ethers.Contract(profileAddress, abi, provider);
const unvetted_contract = new ethers.Contract(
  unvettedAddress,
  unvettedAbi,
  provider
);
const poocoint_contract = new ethers.Contract(poocoinAddress, abi, provider);

const pancakeRouterContract = new ethers.Contract(
  pancakeswap_router,
  abi,
  provider
);
// get pair
export const getRate = async (tokenIn, tokenOut, setRate) => {
  try {
    await pancakeRouterContract
      .getAmountsOut(ethers.utils.parseUnits("1", 18), [tokenIn, tokenOut])
      .then((res) => {
        setRate(parseInt(res[1]) / 1000000000000000000);
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (err) {
    console.log(err);
  }
};

const pancakeswapRouterContract = new web3.eth.Contract(
  router_abi,
  pancakeswap_router
);
// get Amount out
export const getAmountsOut = async (
  amount,
  tokenIn,
  tokenOut,
  updateAmountsOut
) => {
  try {
    if (tokenIn == DefaultTokens.BNB.address) {
      tokenIn = DefaultTokens.WBNB.address;
    }

    if (tokenOut == DefaultTokens.BNB.address) {
      tokenOut = DefaultTokens.WBNB.address;
    }

    const tokenInContract = new web3.eth.Contract(erc20_abi, tokenIn);
    const tokenIn_decimals = await tokenInContract.methods.decimals().call();
    const tokenOutContract = new web3.eth.Contract(erc20_abi, tokenOut);
    const tokenOut_decimals = await tokenOutContract.methods.decimals().call();
    // const amount_in = toBigNum(amount, tokenIn_decimals);
    const amount_in = Number(amount) * 10 ** Number(tokenIn_decimals);

    pancakeswapRouterContract.methods
      .getAmountsOut(amount_in.toString(), [tokenIn, tokenOut])
      .call()
      .then((result) => {
        const amount_out = toHuman(result[1], tokenOut_decimals);
        updateAmountsOut(amount_out);
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (err) {
    console.log(err);
  }
};

//get total supply
export const getTotalSupply = async (tokenAddress) => {
  const getTotalSupply_contract = new ethers.Contract(
    tokenAddress,
    abi,
    provider
  );

  const decimals = await getDecimals(tokenAddress);

  const ts = await getTotalSupply_contract.totalSupply();

  let totalSupply = toHuman(ts._hex ? ts._hex : ts, decimals);

  return totalSupply;
};

const getDecimals = (tokenAddress) => {
  let MyContract1 = new web3.eth.Contract(erc20_abi, tokenAddress);
  return MyContract1.methods.decimals().call();
};

//get reserve
export const getReserve = async (lpAddress, tokenNo) => {
  const getReserves_contract = new ethers.Contract(lpAddress, abi, provider);
  const reserves = await getReserves_contract.getReserves();
  if (tokenNo == 0) {
    let ret = web3.utils.fromWei(reserves[1].toString(), "ether");
    return ret.toString();
  } else {
    let ret = web3.utils.fromWei(reserves[0].toString(), "ether");
    return ret.toString();
  }
};

// async function getPriceBySymbol(symbol) {

//   await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${symbol}&vs_currencies=usd`)
//     .then(res => res.json())
//     .then(data => {
//       // console.log(data.thoreum.usd);
//       return data.symbol.usd;
//     })
//     .catch(err => {

//     })
// }

export const vettedValues = async (setVettedData) => {
  let results = [];
  try {
    await contract.topHolderSize().then((count) => {
      // console.log(parseInt(count._hex)," top holder size");
      for (var i = 0; i < parseInt(count._hex); i++) {
        let item = {};
        contract.holderInfo(0, i).then((address) => {
          // console.log(address," address info");
          profile_contract.userInfo(address[0]).then((res) => {
            // item.price = getPriceBySymbol(res[0]);

            item.name = res[0];
            item.address = address[0];
            item.linkAddress = res[1]; // token address, symbol address
            item.amount = parseInt(address[1]) / 1000000000000000000;

            if (res[0] !== "") results.push(item);
          });
        });
      }

      setTimeout(() => {
        results.sort((a, b) => {
          let keyA = a.amount;
          let keyB = b.amount;
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        });
        setVettedData(results);
      }, 8000);
    });
  } catch (e) {
    console.log(e);
  }
};

export const unvettedValues = async (setUnvettedData) => {
  let unvettedArray = [];
  unvetted_contract.messageLength().then((count) => {
    if (parseInt(count._hex) > 0) {
      for (
        var i = parseInt(count._hex) - 1;
        i > parseInt(count._hex) - 11;
        i--
      ) {
        unvetted_contract.messages(i).then((address) => {
          unvettedArray.push(address);
        });
      }
    } else {
      setUnvettedData(unvettedArray);
    }

    setTimeout(() => {
      setUnvettedData(unvettedArray);
    }, 8000);
  });
};

export const poocoinBalance = async (account, setPoocoinBalanceData) => {
  if (account == null) account = myAccount;

  poocoint_contract.balanceOf(account).then(async (balance) => {
    let decimal = await getDecimals(poocoinAddress);
    setPoocoinBalanceData(parseInt(balance._hex) / 10 ** Number(decimal));
  });
};

export const poocoinLpv1 = async (account, setLpBalanceData) => {
  let lpv1Address = "0x5D0E8f88E4dFAcbf98eD106c2e9dc85ee371eC34"; //Pancake LPs (Cake-LP)
  const lpv1_contract = new ethers.Contract(lpv1Address, abi, provider);
  if (account == null) account = myAccount;

  lpv1_contract.balanceOf(account).then(async (balance) => {
    let decimal = await getDecimals(poocoinAddress);
    setLpBalanceData(parseInt(balance._hex) / 10 ** Number(decimal));
  });
};

export const poocoinLpv2 = async (account, setLpBalanceData) => {
  let lpv2Address = "0x5D0E8f88E4dFAcbf98eD106c2e9dc85ee371eC34"; //Pancake LPs (Cake-LP)
  const lpv2_contract = new ethers.Contract(lpv2Address, abi, provider);
  if (account == null) account = myAccount;
  lpv2_contract.balanceOf(account).then(async (balance) => {
    let decimal = await getDecimals(poocoinAddress);
    setLpBalanceData(parseInt(balance._hex) / 10 ** Number(decimal));
  });
};

let fromBlock = 0;
let apeArray = [];

export const apeLists = async (setApeLists) => {
  web3.eth.getBlockNumber().then((blockNumber) => {
    fromBlock = (blockNumber - 1000).toString(16);

    web3.eth
      .subscribe(
        "logs",
        {
          address: getLogsAddress,
          fromBlock: "0x" + fromBlock,
          toBlock: "latest",
          topics: [topics],
        },
        function (error, result) {
          //   if (!error)
          //       console.log(result);
        }
      )
      .on("connected", function (subscriptionId) {
        console.log("subcriptionID === " + subscriptionId);
      })
      .on("data", function (log) {
        let item = web3.eth.abi.decodeLog(
          [
            {
              type: "string",
              name: "topicsAddress",
              indexed: true,
            },
            {
              type: "address",
              name: "from",
              indexed: true,
            },
            {
              type: "address",
              name: "to",
            },
          ],
          log.data,
          log.topics
        );

        if (item.from !== "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2") {
          // WBNB
          let temp = {};
          temp.from = item.from;
          temp.to = item.to;

          const symbol_contract = new ethers.Contract(item.from, abi, provider);
          symbol_contract.symbol().then((symbol) => {
            temp.symbol = symbol;
            symbol_contract.name().then((name) => {
              temp.name = name;
            });
          });

          apeArray.push(temp);
        }
      })
      .on("changed", function (log) {});

    setTimeout(() => {
      setApeLists(apeArray.reverse());
    }, 8000);
  });
};

const daiAbi = [
  {
    inputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    constant: true,
    inputs: [],
    name: "_decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "_name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "_symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "spender", type: "address" },
    ],
    name: "allowance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    name: "burn",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "subtractedValue", type: "uint256" },
    ],
    name: "decreaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "getOwner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "addedValue", type: "uint256" },
    ],
    name: "increaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    name: "mint",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "sender", type: "address" },
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
];
const token_address = "0x0f304557d7f410385b45f3ce104b8f215126396d"; // SafeMoon (SAFEMOON)

const ownerAbi = [
  "function owner() public view returns (address)",
  "function totalSupply() public view returns (uint256)",
];

const myContract = new web3.eth.Contract(daiAbi, token_address);
const ownerContract = new ethers.Contract(token_address, ownerAbi, provider);

function padValue(value) {
  return value < 10 ? "0" + value : value;
}
function formatDate(dateVal) {
  var newDate = new Date();
  var sYear = newDate.getFullYear();
  var sMonth = newDate.getMonth() + 1;
  var sDay = padValue(newDate.getDate());
  var sHour = newDate.getHours();
  var sMinute = newDate.getMinutes();
  var timeDate = {};

  timeDate["year"] = sYear;
  timeDate["fullmonth"] = padValue(newDate.getMonth() + 1);
  timeDate["month"] = sMonth;

  if (dateVal === "current") {
    timeDate["day"] = sDay;
  } else if (dateVal === "previous") {
    timeDate["day"] = padValue(
      new Date(new Date().valueOf() - 1000 * 60 * 60 * 24).getDate()
    );
    if (sDay == 1) {
      timeDate["month"] =
        new Date(new Date().valueOf() - 1000 * 60 * 60 * 24).getMonth() + 1;
      timeDate["fullmonth"] = padValue(timeDate["month"]);
    }
  }

  timeDate["fullhour"] = padValue(newDate.getHours());

  var sMinute = (Math.round(sMinute / 15) * 15) % 60;
  timeDate["minute"] = padValue(sMinute);

  var sAMPM = "AM";
  var iHourCheck = parseInt(sHour);
  if (iHourCheck > 12) {
    sAMPM = "PM";
    sHour = iHourCheck - 12;
  } else if (iHourCheck === 0) {
    sHour = "12";
  }
  sHour = padValue(sHour);

  timeDate["hour"] = sHour;
  timeDate["ap"] = sAMPM;
  return timeDate;
}

export const devActivity = async (setDevActivityData) => {
  ownerContract.owner().then((res) => {
    let wallet_address = res;

    web3.eth.getBlockNumber().then((blockNumber) => {
      let options = {
        filter: {
          value: [],
        },
        fromBlock: 9876000,
        toBlock: 9881000,
      };

      let transfers = [];

      myContract
        .getPastEvents("Transfer", options)
        .then((results) => {
          for (var i = 0; i < results.length; i++) {
            if (wallet_address === results[i].returnValues.from) {
              var myobj = {
                from: results[i].address, // token Address
                from_address: results[i].returnValues.from,
                to: results[i].returnValues.to,
                amount: results[i].returnValues.value,
                block_number: results[i].blockNumber,
                tx: results[i].transactionHash,
                // symbol: ''
              };

              // const symbol_contract = new ethers.Contract(results[i].address, abi, provider);
              // symbol_contract.symbol().then(symbol => {
              //   myobj.symbol = symbol;

              // })

              transfers.push(myobj);
            }
          }

          // setTimeout(() => {
          setDevActivityData(transfers);
          // }, 8000)
        })
        .catch((err) => console.log(err));
    });
  });
};

const totalSupply = async (setTotalSupplyData) => {
  let temp = {};

  ownerContract.owner().then((res) => {
    let owner_address = res;
    temp.owner = owner_address;

    ownerContract.totalSupply().then((tSupply) => {
      let totalSupply = tSupply;

      temp.totalSupply = web3.utils.toBN(totalSupply).toString();

      // console.log(temp);
      setTotalSupplyData(temp);
    });
  });
};

export const tokenBalance = async (
  wallet_address,
  token_address,
  setTokenBalanceData
) => {
  try {
    const contract = new web3.eth.Contract(erc20_abi, token_address);
    const decimals = await contract.methods
      .decimals()
      .call({ from: wallet_address });
    contract.methods
      .balanceOf(wallet_address)
      .call({ from: wallet_address })
      .then((balance) => {
        setTokenBalanceData(toHuman(balance, decimals));
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (err) {
    console.log(err);
  }
};

export const bnbBalance = (wallet_address, setBnbBalanceData) => {
  try {
    web3.eth
      .getBalance(wallet_address)
      .then((balance) => {
        setBnbBalanceData(web3.utils.fromWei(balance.toString(), "ether"));
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.log(e);
  }
};

export const tokenSwap = async (
  ethereum,
  amount,
  tokenIn,
  tokenOut,
  account,
  miniumAmountOut,
  callback
) => {
  try {
    var mweb3 = new Web3(ethereum);
    var contract = new mweb3.eth.Contract(router_abi, pancakeswap_router);
    contract.setProvider(ethereum);

    if (tokenIn == DefaultTokens.BNB.address) {
      tokenIn = DefaultTokens.WBNB.address;
    }

    if (tokenOut == DefaultTokens.BNB.address) {
      tokenOut = DefaultTokens.WBNB.address;
    }

    const tokenInContract = new web3.eth.Contract(erc20_abi, tokenIn);
    const tokenIn_decimals = await tokenInContract.methods.decimals().call();
    const tokenOutContract = new web3.eth.Contract(erc20_abi, tokenOut);
    const tokenOut_decimals = await tokenOutContract.methods.decimals().call();

    const amount_in = toBigNum(amount, tokenIn_decimals);
    const amount_out = parseInt(
      toBigNum(miniumAmountOut, tokenOut_decimals)
    ).toString();

    if (tokenIn == DefaultTokens.WBNB.address) {
      var tx = await contract.methods
        .swapExactETHForTokens(
          amount_out,
          [tokenIn, tokenOut],
          account,
          Date.now() + 1000 * 60 * 10
        )
        .send({
          from: account,
          value: amount_in,
        });
    } else if (tokenOut == DefaultTokens.WBNB.address) {
      var tx = await contract.methods
        .swapExactTokensForETH(
          amount_in,
          amount_out,
          [tokenIn, tokenOut],
          account,
          Date.now() + 1000 * 60 * 10
        )
        .send({
          from: account,
        });
    } else {
      var tx = await contract.methods
        .swapExactTokensForTokens(
          amount_in,
          amount_out,
          [tokenIn, tokenOut],
          account,
          Date.now() + 1000 * 60 * 10
        )
        .send({
          from: account,
        });

      callback(tx);
    }
  } catch (err) {
    console.log(err);
  }
};

export const getAllowance = (ethereum, account, token, updateAllowance) => {
  try {
    const mweb3 = new Web3(ethereum);
    const contract = new mweb3.eth.Contract(erc20_abi, token);
    contract.setProvider(ethereum);
    const decimals = 18;
    contract.methods
      .allowance(account, pancakeswap_router)
      .call()
      .then((allowance) => {
        updateAllowance(toHuman(allowance, decimals));
      });
  } catch (err) {
    console.log(err);
  }
};

export const approveToken = async (ethereum, token, amount, account) => {
  try {
    const mweb3 = new Web3(ethereum);
    const contract = new mweb3.eth.Contract(erc20_abi, token);
    contract.setProvider(ethereum);

    const tx = await contract.methods
      .approve(
        pancakeswap_router,
        "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
      )
      .send({
        from: account,
      });

    return {
      hash: tx.blockHash,
      status: tx.status,
    };
  } catch (err) {
    return {
      status: false,
    };
  }
};

const toHuman = (num, decimals) => {
  const humanNum = new BigNumber(num).div(
    new BigNumber(10).pow(new BigNumber(decimals))
  );
  return humanNum.toNumber();
};

const toBigNum = (num, decimals) => {
  return new BigNumber(num).times(
    new BigNumber(10).pow(new BigNumber(decimals))
  );
};

//tokens page transaction table - buyer
export const getBuyersData = async (
  tokenAddress,
  currentTimeInfo,
  previousTimeInfo,
  setBuyersValues
) => {
  const currentDate =
    currentTimeInfo.year +
    "-" +
    currentTimeInfo.fullmonth +
    "-" +
    currentTimeInfo.day +
    "T" +
    currentTimeInfo.fullhour +
    ":" +
    currentTimeInfo.minute +
    ":00.000Z";

  const previousDate =
    previousTimeInfo.year +
    "-" +
    previousTimeInfo.fullmonth +
    "-" +
    previousTimeInfo.day +
    "T" +
    previousTimeInfo.fullhour +
    ":" +
    previousTimeInfo.minute +
    ":00.000Z";

  //   const QUERY = `
  //   {
  //     ethereum(network: ethereum) {
  //       dexTrades(
  //         date: {between: ["${previousDate}", "${currentDate}"]}
  //         options: {limit: 20, desc: "block.height"}
  //         baseCurrency: {is: "${tokenAddress}"}
  //       ) {
  //         transaction {
  //           hash
  //         }
  //         block {
  //           height
  //         }
  //         buyCurrency {
  //           symbol
  //           address
  //         }
  //         sellCurrency {
  //           symbol
  //           address
  //         }
  //         buyAmount(calculate: maximum)
  //         sellAmount(calculate: maximum)
  //         any(of: time)
  //         exchange {
  //           fullName
  //         }
  //         tradeAmount(in: USD)
  //        transaction {
  //         txFrom {
  //           address
  //         }
  //       }
  //       }
  //     }
  // }
  //   `;

  const QUERY = `
  {
    ethereum(network: ethereum) {
      dexTrades(
        options: {limit: 20, desc: "block.height"}
        baseCurrency: {is: "${tokenAddress}"}
        quoteCurrency: {in: ["${DefaultTokens.BUSD.address}", "${DefaultTokens.BNB.address}", "${DefaultTokens.USDT.address}", "${DefaultTokens.USDC.address}","${tokenAddress}"]}
        time: {since:"${previousDate}",till: "${currentDate}"}
      ) {
        transaction {
          hash
          txFrom {
            address
          }
        }
        block {
          height
        }
        buyCurrency {
          symbol
          address
        }
        sellCurrency {
          symbol
          address
        }
        buyAmount(calculate: maximum)
        sellAmount(calculate: maximum)
        any(of: time)
        exchange {
          name
        }
        tradeAmount(in: USD)
        side
        baseCurrency {
          symbol
        }
        quoteCurrency {
          symbol
        }
        quotePrice
      }
    }
  }
  
  `;

  const endpoint = "https://graphql.bitquery.io/";
  fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "BQYRgsg3l0o6F7hP74LyPMd657qC8Q2R",
    },
    body: JSON.stringify({
      query: QUERY,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      setBuyersValues(res.data.ethereum.dexTrades);
    });
};

export const getSellersData = async (
  tokenAddress,
  currentTimeInfo,
  previousTimeInfo,
  setSellersValues
) => {
  const currentDate =
    currentTimeInfo.year +
    "-" +
    currentTimeInfo.fullmonth +
    "-" +
    currentTimeInfo.day +
    "T" +
    currentTimeInfo.fullhour +
    ":" +
    currentTimeInfo.minute +
    ":00.000Z";

  const previousDate =
    previousTimeInfo.year +
    "-" +
    previousTimeInfo.fullmonth +
    "-" +
    previousTimeInfo.day +
    "T" +
    previousTimeInfo.fullhour +
    ":" +
    previousTimeInfo.minute +
    ":00.000Z";

  //   const QUERY = `
  //   {
  //     ethereum(network: ethereum) {
  //       dexTrades(
  //         date: {between: ["${previousDate}", "${currentDate}"]}
  //         options: {limit: 20, desc: "block.height"}
  //         baseCurrency: {is: "${tokenAddress}"}
  //       ) {
  //         transaction {
  //           hash
  //         }
  //         block {
  //           height
  //         }
  //         buyCurrency {
  //           symbol
  //           address
  //         }
  //         sellCurrency {
  //           symbol
  //           address
  //         }
  //         buyAmount(calculate: maximum)
  //         sellAmount(calculate: maximum)
  //         any(of: time)
  //         exchange {
  //           fullName
  //         }
  //         tradeAmount(in: USD)
  //  			transaction {
  //         txFrom {
  //           address
  //         }
  //       }
  //       }
  //     }
  // }
  //   `;

  const QUERY = `
{
  ethereum(network: ethereum) {
    dexTrades(
      options: {limit: 20, desc: "block.height"}
      baseCurrency: {is: "${tokenAddress}"}
      quoteCurrency: {in: ["${DefaultTokens.BUSD.address}", "${DefaultTokens.BNB.address}", "${DefaultTokens.USDT.address}", "${DefaultTokens.USDC.address}","${tokenAddress}"]}
      time: {since:"${previousDate}",till: "${currentDate}"}
    ) {
      transaction {
        hash
        txFrom {
          address
        }
      }
      block {
        height
      }
      buyCurrency {
        symbol
        address
      }
      sellCurrency {
        symbol
        address
      }
      buyAmount(calculate: maximum)
      sellAmount(calculate: maximum)
      any(of: time)
      exchange {
        name
      }
      tradeAmount(in: USD)
      side
      baseCurrency {
        symbol
      }
      quoteCurrency {
        symbol
      }
      quotePrice
    }
  }
}

`;

  const endpoint = "https://graphql.bitquery.io/";
  fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "BQYRgsg3l0o6F7hP74LyPMd657qC8Q2R",
    },
    body: JSON.stringify({
      query: QUERY,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      setSellersValues(res.data.ethereum.dexTrades);
    });
};

export const getWalletData = async (tokenAddress, account, setWalletValues) => {
  // await fetch(`/wallet-tx?address=${tokenAddress}&wallet=${account}`)
  //   .then(res => res.json())
  //   .then(data => { setWalletValues(data) })
  //   .catch(err => console.log(err))

  // const QUERY = `
  // {
  //   ethereum(network: ethereum) {
  //     transfers(
  //       options: {desc: "block.timestamp.time", limit: 10, offset: 0}
  //       date: {since: "2019-05-25", till: null}
  //       amount: {gt: 0}
  //       sender: {is: "${account}"}
  //     ) {
  //       block {
  //         timestamp {
  //           time(format: "%Y-%m-%d %H:%M:%S")
  //         }
  //         height
  //       }
  //       sender {
  //         address
  //       }
  //       transaction {
  //         hash
  //       }
  //       amount
  //       currency {
  //         symbol
  //         address
  //       }
  //       external
  //       date {
  //         date(format: "%Y-%m-%d %H:%M:%S")
  //       }
  //     }
  //   }
  // }
  // `;
  let currentTimeInfo = formatDate("current");
  const currentDate =
    currentTimeInfo.year +
    "-" +
    currentTimeInfo.fullmonth +
    "-" +
    currentTimeInfo.day +
    "T" +
    currentTimeInfo.fullhour +
    ":" +
    currentTimeInfo.minute +
    ":00.000Z";
  const QUERY = `
  {
    ethereum(network: ethereum) {
      dexTrades(
        options: {limit: 20, desc: "block.height"}
        buyAmount: {gt: 0} 
        sellAmount: {gt: 0}
        baseCurrency: {is: "${tokenAddress}"}
        quoteCurrency: {in: ["${DefaultTokens.BUSD.address}", "${DefaultTokens.BNB.address}", "${DefaultTokens.USDT.address}", "${DefaultTokens.USDC.address}","${tokenAddress}"]}
        time: {till: "${currentDate}"}
        
        txSender:{is:"${account}"}
      ) {
        transaction {
          hash
          txFrom {
            address
          }
        }
        block {
          height
        }
        buyCurrency {
          symbol
          address
        }
        sellCurrency {
          symbol
          address
        }
        buyAmount(calculate: maximum)
        sellAmount(calculate: maximum)
        any(of: time)
        exchange {
          name
        }
        tradeAmount(in: USD)
        side
        baseCurrency {
          symbol
        }
        quoteCurrency {
          symbol
        }
        quotePrice
      }
    }
  }    
  `;

  const endpoint = "https://graphql.bitquery.io/";
  const data = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "BQYRgsg3l0o6F7hP74LyPMd657qC8Q2R",
    },
    body: JSON.stringify({
      query: QUERY,
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      setWalletValues(
        data.data.ethereum.dexTrades.length > 0
          ? data.data.ethereum.dexTrades
          : []
      );
    })
    .catch((err) => console.log(err));
};

export const getOwnToken_wallet = async (
  accountAddress,
  setWalletTokenData
) => {
  let currencies = await getOwnToken(accountAddress);

  if (currencies != null) {
    for (let i = 0; i < currencies.length; i++) {
      const currencyAddress = currencies[i].currency.address;

      if (currencyAddress != "-") {
        try {
          //own token contract
          const tokenIn_decimals = await getDecimals(currencyAddress);
          //BUSD token contract
          const tokenOut_decimals = await getDecimals(
            DefaultTokens.WBNB.address
          );

          const amount_in = 1 * 10 ** Number(tokenIn_decimals);
          const amount_out = await pancakeswapRouterContract.methods
            .getAmountsOut(amount_in.toString(), [
              currencyAddress,
              DefaultTokens.WBNB.address,
            ])
            .call();

          const tokenRate = toHuman(amount_out[1], tokenOut_decimals);

          const usdRate = await fetch(
            `https://min-api.cryptocompare.com/data/price?fsym=WETH&tsyms=USD`
          );
          let usd = await usdRate.json();
          usd = Number(usd.USD);

          currencies[i]["rate"] = Number(tokenRate * usd).toFixed(6);
          currencies[i]["rateAmount"] = (
            tokenRate *
            usd *
            currencies[i].value
          ).toFixed(2);
        } catch (err) {
          const tokenRate = 0;
          currencies[i]["rate"] = tokenRate.toFixed(4);
          currencies[i]["rateAmount"] = tokenRate.toFixed(4);
          continue;
        }
      } else {
        //own token contract
        const tokenIn_decimals = await getDecimals(DefaultTokens.WBNB.address);
        //BUSD token contract
        const tokenOut_decimals = await getDecimals(DefaultTokens.USDT.address);

        // const amount_in = toBigNum(1, tokenIn_decimals);
        const amount_in = 1 * 10 ** Number(tokenIn_decimals);
        const amount_out = await pancakeswapRouterContract.methods
          .getAmountsOut(amount_in.toString(), [
            DefaultTokens.WBNB.address,
            DefaultTokens.USDT.address,
          ])
          .call();
        const tokenRate = toHuman(amount_out[1], tokenOut_decimals);

        currencies[i]["rate"] = tokenRate.toFixed(4);
        currencies[i]["rateAmount"] = (tokenRate * currencies[i].value).toFixed(
          2
        );
        currencies[i].currency["address"] = DefaultTokens.WBNB.address;
      }
    }
    if (currencies.length == 0) {
      //own token contract
      const tokenIn_decimals = await getDecimals(DefaultTokens.WBNB.address);
      //BUSD token contract
      const tokenOut_decimals = await getDecimals(DefaultTokens.USDT.address);

      // const amount_in = toBigNum(1, tokenIn_decimals);
      const amount_in = 1 * 10 ** Number(tokenIn_decimals);
      const amount_out = await pancakeswapRouterContract.methods
        .getAmountsOut(amount_in.toString(), [
          DefaultTokens.WBNB.address,
          DefaultTokens.USDT.address,
        ])
        .call();
      const tokenRate = toHuman(amount_out[1], tokenOut_decimals);

      const tokenValue = 0;
      const currencyData = {
        address: DefaultTokens.WBNB.address,
        symbol: "ETH",
      };
      const tokenInfoArray = {
        currency: currencyData,
        rate: tokenRate.toFixed(4),
        value: tokenValue.toFixed(2),
        rateAmount: tokenValue.toFixed(2),
      };
      currencies.push(tokenInfoArray);
    }
  } else {
    currencies = [];
  }
  setWalletTokenData(currencies);
};

export const getTransactionList = async (
  tokenAddress,

  setTransactionListData
) => {
  if (tokenAddress != null) {
    const transactionLists = await getTransactionListData(tokenAddress);

    let transaction = [];
    if (transactionLists != null) {
      for (let i = 0; i < transactionLists.length; i++) {
        try {
          let time = new Date(transactionLists[i].any);
          // let time_str = time.toISOString().split(".");
          let hour = time.getHours();
          let minute = time.getMinutes();
          let second = time.getSeconds();

          var sAMPM = "AM";
          var iHourCheck = parseInt(hour);

          if (iHourCheck > 12) {
            sAMPM = "PM";
            hour = iHourCheck - 12;
          } else if (iHourCheck === 0) {
            hour = "12";
          }
          let transactionTime = hour + ":" + minute + ":" + second;
          // const tokenPrice = await getPriceByTime(tokenAddress, time_str[0]);
          // await delay(2000);

          let coinPrice = transactionLists[i].tradeAmount;

          let exchangeName = transactionLists[i].exchange.name;

          if (exchangeName == "Uniswap") {
            exchangeName = "Uniswap";
          } else if (exchangeName == "Uniswap v1") {
            exchangeName = "Uniswap v1";
          } else if (exchangeName == "Uniswap v2") {
            exchangeName = "Uniswap v2";
          }

          if (
            transactionLists[i].buyCurrency.address ==
            tokenAddress.toLowerCase()
          ) {
            transaction.push({
              tokenNum: numberWithCommas(
                parseFloat(transactionLists[i].buyAmount).toFixed(2)
              ),
              tokenSymbol: transactionLists[i].buyCurrency.symbol,
              coinNum: transactionLists[i].sellAmount,
              coinSymbol: transactionLists[i].sellCurrency.symbol,
              // "tokenPrice": tokenPrice,
              transactionTime: transactionTime,
              AMPM: sAMPM,
              // "coinPrice": parseInt(transactionLists[i].buyAmount) * tokenPrice,
              coinPrice: coinPrice,
              tokenPrice: Number(transactionLists[i].quotePrice).toFixed(6),
              status: "buy",
              txHash: transactionLists[i].transaction.hash,
              exchangeName: exchangeName,
            });
          } else if (
            transactionLists[i].sellCurrency.address ==
            tokenAddress.toLowerCase()
          ) {
            transaction.push({
              tokenNum: numberWithCommas(
                parseFloat(transactionLists[i].sellAmount).toFixed(2)
              ),
              tokenSymbol: transactionLists[i].sellCurrency.symbol,
              coinNum: transactionLists[i].buyAmount,
              coinSymbol: transactionLists[i].buyCurrency.symbol,
              // "tokenPrice": tokenPrice,
              tokenPrice: Number(transactionLists[i].quotePrice).toFixed(6),
              transactionTime: transactionTime,
              AMPM: sAMPM,
              coinPrice: coinPrice,
              status: "sell",
              txHash: transactionLists[i].transaction.hash,
              exchangeName: exchangeName,
            });
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
    setTransactionListData(transaction);
  }
};
