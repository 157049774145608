import { createReducer } from "@reduxjs/toolkit";
import { tokenAddress, tokenName, trackAddress } from "./action";

const initialState = {
  tokenAddress: "",
  tokenName: "",
  trackAddress: "",
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(tokenAddress, (state, { payload: { address } }) => {
      console.log("address", address);
      return {
        ...state,
        tokenAddress: address,
      };
    })
    .addCase(tokenName, (state, action) => {
      return {
        ...state,
        tokenName: action.payload,
      };
    })
    .addCase(trackAddress, (state, { payload: { address } }) => {
      console.log("address", address);
      return {
        ...state,
        trackAddress: address,
      };
    })
);

// export const tokenReducer = createSlice({
//   name: "tokeninfo",
//   initialState,
//   reducers: {
//     tokenAddress: (state, action) => {
//       return {
//         ...state,
//         tokenAddress: action.payload,
//       };
//     },
//     tokenName: (state, action) => {
//       return {
//         ...state,
//         tokenName: action.payload,
//       };
//     },
//     trackAddress: (state, action) => {
//       return {
//         ...state,
//         trackAddress: action.payload,
//       };
//     },
//   },
// });
// export const { tokenAddress, tokenName, trackAddress } = tokenReducer.actions;

// export default tokenReducer.reducer;
