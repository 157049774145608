import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Tab from "../Component/basic/hometab";
import Input from "../Component/basic/input";
import TokenSelect from "../Component/TokenSelect";
import { APIURL, serverUrl } from "../constants";
import PoocoinIcon from "../Images/adds.gif";
import telegram from "../Images/telegram.svg";
import twitter from "../Images/twitter.png";
import { tokenAddress } from "./TradeDex/state/tokenInfo/action";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    color: "black",
    paddingBottom: "20px",
    // fontFamily: "'Super Mario 256', 'sans-serif'",
  },
  content: {},
  hotPairs: {
    backgroundColor: "rgba(255,255,255,0.1)",
    borderRadius: "10px",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  title: {
    fontSize: "2.5em",
    fontFamily: "Roboto Flex",
    fontWeight: 700,
  },
  pair: {
    width: "40%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
  },
  tokenSelect: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
  },
  centerText: {
    fontSize: 30,
    marginTop: 10,
  },
  bottomText: {
    fontSize: "20px !important",
    marginTop: 10,
  },
  inputWidth: {
    width: "100%",
    padding: "1em 1em 1em 1em",
  },
  tabContainer: {
    minHeight: "700px !important",
  },
  rightSide: {
    margin: "17px auto 20px auto",
    backgroundColor: "rgba(255,255,255,0.1)",
    maxWidth: 800,
    minWidth: 300,
    borderRadius: "8px",
    padding: "10px",
  },
  topSide: {
    marginLeft: "19%",
    marginTop: 20,
    color: "white",
  },
  pageHeader: {
    height: "auto",
    padding: "20px",
    color: "#fff",
  },
  tLink: {
    color: "#A4FF66",
    fontSize: "1.5rem",
    textDecoration: "none",
    flexWrap: "break-wrap",
    cursor: "pointer",
  },
  tLink: {
    color: "#A4FF66",
    fontSize: "1.5rem",
    textDecoration: "none",
    flexWrap: "break-wrap",
    cursor: "pointer",
  },
  video: {
    width: "100%",
    height: "250px",
    objectFit: "cover",
  },
}));

export default function Home() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [bannerHome, setBannerHome] = useState(null);
  const [bannerLink, setBannerLink] = useState(null);
  const [hotTokens, setHotTokens] = useState([]);
  const [bannerExtension, setBannerExtension] = useState("image");

  const inputHandle = (tokenAddress1) => {
    history.push(`/tokens/${tokenAddress1}`);
    dispatch(tokenAddress({ address: tokenAddress1 }));
    // dispatch({ type: "SET_TOKENADDRESS", payload: tokenAddress });
  };

  const handleTokenPropsChange = (tokenInfo) => {
    // console.log(tokenInfo," tokenInfo");
    let hdata = localStorage.getItem("history");
    let array = [];
    let data = JSON.parse(hdata);
    if (data != undefined && data.length > 0) {
      if (data.some((e) => e.address === tokenInfo.address)) {
      } else {
        data.push(tokenInfo);
        localStorage.setItem("history", JSON.stringify(data));
      }
    } else {
      array.push(tokenInfo);
      localStorage.setItem("history", JSON.stringify(array));
    }
    const address = tokenInfo.address;
    history.push(`/tokens/${address}`);
    dispatch(tokenAddress(address));
    // dispatch({ type: "SET_TOKENADDRESS", payload: address });
  };

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { data },
        } = await axios.get(`${serverUrl}hotpairs`);

        setHotTokens(data?.data[0].data);

        const {
          data: { data: result },
        } = await axios.get(`${serverUrl}getbanner`);
        if (result.length > 0) {
          setBannerHome(result[0].bannerImage);
          setBannerLink(result[0].bannerLink);
          setBannerExtension(result[0].bannerExtension);
        } else {
          setBannerHome(null);
          setBannerLink(null);
          setBannerExtension(null);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.pageHeader}>
        <div className="container mb-3">
          <a href="#" target="_blank">
            {/* <img src={addIcon} width="100%" />  */}
            {bannerExtension == "image" && (
              <img
                src={
                  bannerHome == null ? PoocoinIcon : APIURL + "/" + bannerHome
                }
                className={classes.icon}
                width="80%"
                alt=""
              />
            )}
            {bannerExtension == "video" && (
              <video className={classes.video} controls>
                <source
                  src={
                    bannerHome == null ? PoocoinIcon : APIURL + "/" + bannerHome
                  }
                />
              </video>
            )}
          </a>
        </div>

        <Box>
          <p className={classes.title}>Hot Tokens</p>
          <Box className={classes.hotPairs}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                columnGap: "8px",
              }}
            >
              {hotTokens.slice(0, 10).map(({ token0, token1 }, i) => {
                return (
                  <Link
                    to={`/tokens/${token0.id}`}
                    className={classes.pair}
                    style={{ textAlign: "start" }}
                  >
                    <Box
                      key={i}
                      style={{ marginTop: (i === 0 || i === 1) && "10px" }}
                    >
                      <p style={{ color: "#fff" }}>
                        #{i + 1}
                        <span
                          style={{ marginLeft: "10px", color: "#A4FF66" }}
                        >{`${token0?.symbol}/${token1?.symbol}`}</span>{" "}
                      </p>
                    </Box>
                  </Link>
                );
              })}
            </Box>
          </Box>
        </Box>

        <h2 className={classes.title}>ETH Charts</h2>
        <div className={classes.centerText} fontWeight="bold">
          View your wallets token portfolio (Ethereum)
        </div>
        {/* <div className="twi_sec">
        Telegram & Twitter public chat &nbsp;
  </div> */}
        <div className={classes.bottomText}>
          <a
            href="https://t.me/anon_logic"
            className="ban_link"
            rel="noreferrer"
            target="_blank"
          >
            <img src={telegram} width="25px" alt="" /> Moonchanportal
          </a>
          <a
            href="https://twitter.com/Moonchan_Eth"
            className="ban_link"
            rel="noreferrer"
            target="_blank"
          >
            <img src={twitter} width="25px" alt="" /> Moonchan_eth
          </a>
        </div>
        <br></br>
        {/* <div className={classes.bottomText}>
        Twitter public chat: &nbsp;
          <a href="https://mobile.twitter.com/okapi_eth" className="ban_link" target="_blank">
          <img src={twitter} width="25px"></img> Okapi_eth
          </a>
  </div> */}
      </div>
      <div className={classes.content}>
        <div className={classes.tokenSelect}>
          <TokenSelect
            inputHandle={inputHandle}
            tokenProps={handleTokenPropsChange}
          />
        </div>
        <div className={classes.rightSide}>
          <div className={classes.inputWidth}>
            <Input />
          </div>
          <Tab className={classes.tabContainer} />
        </div>
      </div>
    </div>
  );
}
