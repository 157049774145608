import React, { createContext, useState } from "react";
// import WalletLink from "walletlink";
import { MaxUint256 } from "@ethersproject/constants";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { providers } from "ethers";
import Web3Modal from "web3modal";
import { calculateGasMargin } from ".";
import { connectWalletStatus } from "../../../constants";
// const INFURA_ID = "460f40a260564ac4a4f4b3fffb032dad";
const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      networkUrl:
        "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
      rpc: {
        1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
      },
      chainId: 1,
    },
    network: "mainnet",
  },
};
const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true,
  providerOptions, // required
  theme: {
    background: "rgb(28, 33, 53)",
    main: "rgb(199, 199, 199)",
    secondary: "rgb(136, 136, 136)",
    // border: "rgba(195, 195, 195, 0.14)",
    hover: "rgb(16, 26, 32)",
  },
});
let initialState = {
  provider: null,
  web3Provider: null,
  account: null,
  chainId: null,
  signer: null,
  balance: null,
};
export const gasEstimationForAll = async (account, fn, data) => {
  if (account) {
    const estimateGas = await fn(...data, MaxUint256).catch(() => {
      return fn(...data);
    });
    return calculateGasMargin(estimateGas);
  }
};
export const AppContext = createContext(initialState);
export const AppContextProvider = ({ children }) => {
  let connectStatus = localStorage.getItem("PoocoinConnectStatus"); //status connect to metamask
  if (connectStatus == null) {
    connectStatus = connectWalletStatus.disconnect;
    localStorage.setItem(
      "PoocoinConnectStatus",
      connectWalletStatus.disconnect
    );
  }

  const [state, setState] = useState(initialState);

  const connect = async () => {
    const provider = await web3Modal.connect();
    const web3Provider = new providers.Web3Provider(provider);
    const signer = web3Provider.getSigner();
    const account = await signer.getAddress();
    const balance = await web3Provider.getBalance(account);
    const network = await web3Provider.getNetwork();
    localStorage.setItem("PoocoinConnectStatus", 1);

    setState({
      ...state,
      provider: provider,
      web3Provider: web3Provider,
      account: account,
      signer: signer,
      chainId: network.chainId,
      balance: balance,
    });
  };

  //CalculatePayableGas

  const disconnect = React.useCallback(
    async function () {
      await web3Modal.clearCachedProvider();
      if (
        state.provider?.disconnect &&
        typeof state.provider.disconnect === "function"
      ) {
        await state.provider.disconnect();
      }

      setState({
        ...state,
        provider: null,
        web3Provider: null,
        account: null,
        chainId: null,
        signer: null,
      });

      window.location.reload();
    },
    [state]
  );

  React.useEffect(() => {
    if (state.provider?.on) {
      const handleAccountsChanged = (accounts) => {
        // eslint-disable-next-line no-console
        setState({
          ...state,
          account: accounts[0],
        });
      };
      const handleChainChanged = (_hexChainId) => {
        window.location.reload();
      };
      const handleDisconnect = (error) => {
        // eslint-disable-next-line no-console
        disconnect();
      };
      state.provider.on("accountsChanged", handleAccountsChanged);
      state.provider.on("chainChanged", handleChainChanged);
      state.provider.on("disconnect", handleDisconnect);
      // Subscription Cleanup
      return () => {
        if (state.provider.removeListener) {
          state.provider.removeListener(
            "accountsChanged",
            handleAccountsChanged
          );
          state.provider.removeListener("chainChanged", handleChainChanged);
          state.provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [state.provider, disconnect]);
  return (
    <AppContext.Provider
      value={{
        account: state.account,
        signer: state.signer,
        provider: state.provider,
        balance: state.balance,
        connect,
        disconnect,
        chainId: state.chainId,
        web3Provider: state.web3Provider,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
