import { Currency, currencyEquals, ETHER, Token } from "moonchan-sdk";
import { useCallback, useContext, useMemo } from "react";
import { FixedSizeList } from "react-window";

import { Box, Button, CircularProgress, useTheme } from "@material-ui/core";
import { useIsUserAddedToken } from "../../hooks/Tokens";
import {
  useSelectedTokenList,
  WrappedTokenInfo,
} from "../../state/lists/hooks";
import {
  useAddUserToken,
  useRemoveUserAddedToken,
} from "../../state/user/hooks";
import { useCurrencyBalance } from "../../state/wallet/hooks.js";
import { isTokenOnList } from "../../utils";
import { AppContext } from "../../utils/Utils";
import CurrencyLogo from "../CurrencyLogo/CurrencyLogo";

function currencyKey(currency) {
  return currency instanceof Token
    ? currency.address
    : currency === ETHER
    ? "WBNB"
    : "";
}

function Balance({ balance }) {
  const theme = useTheme();
  return (
    <Box color={theme.palette.common.black} title={balance.toExact()}>
      {balance.toSignificant(4)}
    </Box>
  );
}

function CurrencyRow({ currency, onSelect, isSelected, otherSelected, style }) {
  const { account, chainId } = useContext(AppContext);
  const key = currencyKey(currency);
  const selectedTokenList = useSelectedTokenList();
  const isOnSelectedList = isTokenOnList(selectedTokenList, currency);
  const customAdded = useIsUserAddedToken(currency);
  const balance = useCurrencyBalance(account ?? undefined, currency);
  const removeToken = useRemoveUserAddedToken();
  const addToken = useAddUserToken();
  const theme = useTheme();
  // only show add or remove buttons if not on selected list
  return (
    <>
      <Box
        className={`token-item-${key}`}
        onClick={() => (isSelected ? null : onSelect())}
        sx={{
          opacity: isSelected ? 0.5 : 1,
          cursor: isSelected ? "no-drop" : "pointer",
          "&:hover": {
            background: "#13151fa1",
          },
        }}
        selected={otherSelected}
        display="flex"
        ml={1}
        borderRadius="16px"
        justifyContent="space-between"
        p={2}
      >
        <Box
          fontWeight="Light"
          display="flex"
          fontSize="16px"
          mb={2}
          alignItems="center"
          color="primary.contrastText"
        >
          <CurrencyLogo currency={currency} size="24px" />
          <Box ml={1} color="common.black" title={currency.name}>
            {currency.symbol}
          </Box>
          <Box color={theme.palette.common.black} ml={1}>
            {!isOnSelectedList &&
            customAdded &&
            !(currency instanceof WrappedTokenInfo) ? (
              <Box>
                Added by user
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    if (chainId && currency instanceof Token)
                      removeToken(chainId, currency.address);
                  }}
                  style={{ color: theme.palette.common.black }}
                >
                  (Remove)
                </Button>
              </Box>
            ) : null}
            {!isOnSelectedList &&
            !customAdded &&
            !(currency instanceof WrappedTokenInfo) ? (
              <Box>
                Found by address
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    if (currency instanceof Token) addToken(currency);
                  }}
                  style={{ color: theme.palette.common.black }}
                >
                  (Add)
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>
        {/* <TokenTags currency={currency} /> */}

        <Box
          fontFamily="Sandro"
          fontWeight="Light"
          fontSize="12px"
          color="primary.contrastText"
          style={{ justifySelf: "flex-end", marginRight: "20px" }}
        >
          {balance ? (
            <Balance balance={balance} />
          ) : account ? (
            <Box>
              <CircularProgress size={20} color="secondary.main" />
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  );
}

export default function CurrencyList({
  height,
  currencies,
  selectedCurrency,
  onCurrencySelect,
  otherCurrency,
  fixedListRef,
  showETH,
}) {
  const itemData = useMemo(
    () => (showETH ? [Currency.ETHER, ...currencies] : [...currencies]),
    [currencies, showETH]
  );

  const Row = useCallback(
    ({ data, index, style }) => {
      const currency = data[index];
      const isSelected = Boolean(
        selectedCurrency && currencyEquals(selectedCurrency, currency)
      );
      const otherSelected = Boolean(
        otherCurrency && currencyEquals(otherCurrency, currency)
      );
      const handleSelect = () => onCurrencySelect(currency);
      return (
        <CurrencyRow
          style={style}
          currency={currency}
          isSelected={isSelected}
          onSelect={handleSelect}
          otherSelected={otherSelected}
        />
      );
    },
    [onCurrencySelect, otherCurrency, selectedCurrency]
  );

  const itemKey = useCallback((index, data) => currencyKey(data[index]), []);
  console.log(itemData.length, "itemData");
  return (
    <Box className="eth_modalItemsScroll" m="10px">
      <FixedSizeList
        style={{ overflow: "hidden !important" }}
        height={290}
        ref={fixedListRef}
        width="100%"
        itemData={itemData}
        itemCount={itemData.length}
        itemSize={8}
        itemKey={itemKey}
      >
        {Row}
      </FixedSizeList>
    </Box>
  );
}
