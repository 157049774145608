import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import theme from "../../theme";

const CustomButton = withStyles({
  root: {
    background: "#A4FF66",
    // background:
    //   "linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(106.27deg, #ED2196 16.3%, #ED4621 87.94%)",
    color: "#000",
    fontFamily: "Roboto",
    fontWeight: "Bold",
    fontSize: "19px",
    height: "60px",
    borderRadius: "16px",
    "&:hover": {
      background: "transparent",
      color: "#A4FF66",
      border: "2px solid #A4FF66",
    },
    "&:disabled": {
      background: theme.palette.common.tooltip,
      boxShadow: "#ffcdd6",
    },
  },
})(Button);

export default function CustomizedButton({ children, fun, disabled }) {
  return (
    <CustomButton
      onClick={fun}
      disabled={disabled}
      style={{
        width: "80%",
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      {children}
    </CustomButton>
  );
}
