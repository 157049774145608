import { CircularProgress, Modal } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTransactionList } from "../../../PooCoin";

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#0f1a1c",
    color: theme.palette.common.white,
    // padding: '0 0 0 10px',
    borderColor: "#0f1a1c",
    padding: 0,
  },
  body: {
    fontSize: 12,
    lineHeight: 1.43,
    // padding: 0,
    // paddingLeft: 10,
    color: "#fff",
    borderColor: "#0f1a1c",
    maxHeight: 300,
    overflow: "auto",
    padding: 0,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#141414",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#141722",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 100,
    maxHeight: 200,
    overflow: "auto",
    fontSize: "14px !important",
    fontFamily: '"Lato",sans-serif',
    paddingTop: "0px",
  },
  row: {
    color: "#28a745 !important",
  },
  tokenInfosell: {
    color: "#dc3545!important",
    textAlign: "right",
    padding: "5px",
    fontSize: "13px",
  },
  tokenInfobuy: {
    color: "#28a745 !important",
    textAlign: "right",
    padding: "5px",
    fontSize: "13px",
  },
  txValue: {
    color: "#A4FF66 !important",
    padding: "5px",
  },
  tableBody: {
    maxHeight: 300,
    overflow: "auto",
  },
  th: {
    textAlign: "right",
    padding: "5px",
  },
  CircularProgress: {
    color: "#b2b5be",
    marginTop: "20px",
  },
  link: {
    color: "#A4FF66",
    fontSize: "13px",
    fontWeight: "bold",
    cursor: "pointer",
  },
  modalLeft: {
    textAlign: "left",
    color: "#A4FF66 !important",
    cursor: "pointer",
  },
  modalRight: {
    textAlign: "right",
    cursor: "pointer",
    color: "#A4FF66 !important",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#0f1a1c",
  },
  paper: {
    backgroundColor: "white",
    border: "none",
    padding: "40px 30px 30px 30px",
    display: "grid",
    borderRadius: "8px",
    textAlign: "center",
  },
});

export default function CustomizedTables({ tokenPrice }) {
  const classes = useStyles();
  const tokenAddress = useSelector((state) => state.tokenInfo.tokenAddress);
  const [transactionLists, setTransactionLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setModalOpen] = useState(false);

  const modalClose = () => {
    setModalOpen(false);
  };

  const modalOpen = () => {
    setModalOpen(true);
  };

  const setTransactionData = (data) => {
    if (data.length === 0) {
      setLoading(true);
    } else {
      setLoading(false);
      setTransactionLists(data);
    }
  };

  useEffect(() => {
    getTransactionList(tokenAddress, setTransactionData);
  }, [tokenAddress, tokenPrice]);

  useEffect(() => {
    if (tokenAddress == undefined) return;
    getTransactionList(tokenAddress, setTransactionData);
  }, [tokenAddress]);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {/* <StyledTableCell className={classes.th}>State</StyledTableCell> */}
              <StyledTableCell className={classes.th}>Tokens</StyledTableCell>
              {/* <StyledTableCell className={classes.th}>TokenName</StyledTableCell> */}
              <StyledTableCell className={classes.th}>Price</StyledTableCell>
              <StyledTableCell className={classes.th}>
                Price / Token
              </StyledTableCell>
              <StyledTableCell className={classes.th}>Time</StyledTableCell>
              <StyledTableCell>Tx</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {transactionLists != undefined &&
              transactionLists.map((transactionList, index) => (
                <StyledTableRow key={index} className={classes.row}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    className={
                      transactionList.status === "buy"
                        ? classes.tokenInfosell
                        : classes.tokenInfobuy
                    }
                  >
                    {transactionList.tokenNum}
                    <div>{transactionList.tokenSymbol}</div>
                  </StyledTableCell>
                  <StyledTableCell
                    className={
                      transactionList.status === "buy"
                        ? classes.tokenInfosell
                        : classes.tokenInfobuy
                    }
                  >
                    ${transactionList.coinPrice}
                    <div>
                      {transactionList.coinNum + transactionList.coinSymbol}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    className={
                      transactionList.status === "buy"
                        ? classes.tokenInfosell
                        : classes.tokenInfobuy
                    }
                  >
                    ${transactionList.tokenPrice}
                    <div>{transactionList.exchangeName}</div>
                  </StyledTableCell>
                  <StyledTableCell
                    className={
                      transactionList.status === "buy"
                        ? classes.tokenInfosell
                        : classes.tokenInfobuy
                    }
                  >
                    {transactionList.transactionTime}
                    <div className={classes.tokenInfo}>
                      {transactionList.AMPM}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell className={classes.txValue}>
                    <a
                      className={classes.link}
                      target="_blank"
                      href={`https://etherscan.io/tx/${transactionList.txHash}`}
                      rel="noreferrer"
                    >
                      {transactionList.txHash.substring(0, 6)}
                    </a>
                    <div className={classes.link} onClick={() => modalOpen()}>
                      Track
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && (
        <CircularProgress size={20} className={classes.CircularProgress} />
      )}
      <Modal className={classes.modal} open={open} onClose={modalClose}>
        <div className={classes.paper}>
          <h1>Premium required</h1>
          <p>This features requires premium tier 1.</p>
          <p>
            Unlock this premium tier by holding $100 worth of{" "}
            <a
              className={"textBlue fs3"}
              target="_blank"
              href="https://app.sushi.com/add/ETH/0x0f304557d7f410385b45f3ce104b8f215126396d"
              rel="noreferrer"
            >
              Moonchan
            </a>
          </p>
          <p>
            (approximately Infinity moonchan created from Infinity{" "}
            <a
              className={"textBlue fs3"}
              target="_blank"
              rel="noreferrer"
              href="https://app.uniswap.org/#/swap?inputCurrency=0x0f304557d7f410385b45f3ce104b8f215126396d&outputCurrency=0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
            >
              Moonchan
            </a>
            ) tokens in your wallet.
          </p>
        </div>
      </Modal>
    </div>
  );
}
