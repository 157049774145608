import DefaultTokens from "../config/default_tokens.json";

// {
//   ethereum(network: ethereum) {
//     transactions(
//       options: {desc: "block.timestamp.time", limit: 200, offset: 0}
//       txSender: {is: "0x2cdA25C0657d7622E6301bd93B7EC870a56fE500"}
//     ) {
//       block {
//         timestamp {
//           time(format: "%Y-%m-%d %H:%M:%S")
//         }
//         height
//       }
//       success
//       address: to {
//         address
//         annotation
//       }
//       gasValue
//       gas_value_usd: gasValue(in: USD)
//       gasCurrency {
//         symbol
//       }
//       hash
//       amount
//     }
//   }
// }

export const getOwnToken = async (accountAddress) => {
  const QUERY = `{
  ethereum(network: ethereum) {
    address(address: {is: "${accountAddress}"}) {
      balances {
        currency {
          address
          symbol
          tokenType
        }
        value
      }
    }
  }
}`;

  // -------- Endpoint ----------------------
  const endpoint = "https://graphql.bitquery.io/";

  // Function which fetches the data from the API
  const response = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "BQYRgsg3l0o6F7hP74LyPMd657qC8Q2R",
    },
    body: JSON.stringify({
      query: QUERY,
    }),
  }).catch(() => {
    return null;
  });

  if (response == null) {
    return null;
  }

  const data = await response.json();
  if (data.data.ethereum.address[0].balances == null) {
    return [];
  }
  const currency = data.data.ethereum.address[0].balances;
  return currency;
};

export const getTransactionListData = async (tokenAddress) => {
  // any: {baseCurrency: {is: "${tokenAddress}"}}
  //   const QUERY = `{
  //     ethereum(network: ethereum) {
  //       dexTrades(
  //         options: {limit: 20, desc: "block.height"}
  //         baseCurrency: {is: "${tokenAddress}"}
  //       ) {
  //         transaction {
  //           hash
  //         }
  //         block {
  //           height
  //         }
  //         buyCurrency {
  //           symbol
  //           address
  //         }
  //         sellCurrency {
  //           symbol
  //           address
  //         }
  //         buyAmount(calculate: maximum)
  //         sellAmount(calculate: maximum)
  //         any(of: time)
  //         exchange {
  //           fullName
  //         }
  //         tradeAmount(in: USD)
  //       }
  //     }
  // }`;
  function padValue(value) {
    return value < 10 ? "0" + value : value;
  }
  function formatDate(dateVal) {
    var newDate = new Date();
    var sYear = newDate.getFullYear();
    var sMonth = newDate.getMonth() + 1;
    var sDay = padValue(newDate.getDate());
    var sHour = newDate.getHours();
    var sMinute = newDate.getMinutes();
    var timeDate = {};

    timeDate["year"] = sYear;
    timeDate["fullmonth"] = padValue(newDate.getMonth() + 1);
    timeDate["month"] = sMonth;

    if (dateVal === "current") {
      timeDate["day"] = sDay;
    } else if (dateVal === "previous") {
      timeDate["day"] = padValue(
        new Date(new Date().valueOf() - 1000 * 60 * 60 * 24).getDate()
      );
      if (sDay == 1) {
        timeDate["month"] =
          new Date(new Date().valueOf() - 1000 * 60 * 60 * 24).getMonth() + 1;
        timeDate["fullmonth"] = padValue(timeDate["month"]);
      }
    }

    timeDate["fullhour"] = padValue(newDate.getHours());

    var sMinute = (Math.round(sMinute / 15) * 15) % 60;
    timeDate["minute"] = padValue(sMinute);

    var sAMPM = "AM";
    var iHourCheck = parseInt(sHour);
    if (iHourCheck > 12) {
      sAMPM = "PM";
      sHour = iHourCheck - 12;
    } else if (iHourCheck === 0) {
      sHour = "12";
    }
    sHour = padValue(sHour);

    timeDate["hour"] = sHour;
    timeDate["ap"] = sAMPM;
    return timeDate;
  }

  const currentTimeInfo = formatDate("current");
  const currentDate =
    currentTimeInfo.year +
    "-" +
    currentTimeInfo.fullmonth +
    "-" +
    currentTimeInfo.day +
    "T" +
    currentTimeInfo.fullhour +
    ":" +
    currentTimeInfo.minute +
    ":00.000Z";

  const QUERY = `
{
  ethereum(network: ethereum) {
    dexTrades(
      options: {limit: 20, desc: "block.height"}
      buyAmount: {gt: 0}
      sellAmount: {gt: 0}
      baseCurrency: {is: "${tokenAddress}"}
      quoteCurrency: {in: ["${DefaultTokens.BUSD.address}", "${DefaultTokens.BNB.address}", "${DefaultTokens.USDT.address}", "${DefaultTokens.USDC.address}","${tokenAddress}"]}
      time: {till: "${currentDate}"}
    ) {
      transaction {
        hash
        txFrom {
          address
        }
      }
      block {
        height
      }
      buyCurrency {
        symbol
        address
      }
      sellCurrency {
        symbol
        address
      }
      buyAmount(calculate: maximum)
      sellAmount(calculate: maximum)
      any(of: time)
      exchange {
        name
      }
      tradeAmount(in: USD)
      side
      baseCurrency {
        symbol
      }
      quoteCurrency {
        symbol
      }
      quotePrice
    }
  }
}

`;
  // -------- Endpoint ----------------------
  const endpoint = "https://graphql.bitquery.io/";

  // Function which fetches the data from the API
  const response = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "BQYRgsg3l0o6F7hP74LyPMd657qC8Q2R",
    },
    body: JSON.stringify({
      query: QUERY,
    }),
  }).catch(() => {
    return null;
  });
  if (response == null) {
    return null;
  }
  const data = await response.json();

  const currency = data.data.ethereum.dexTrades;

  return currency;
};

export const getPriceByTime = async (tokenAddress, time) => {
  const QUERY = `{
    ethereum(network: ethereum) {
      dexTrades(
        options: {desc: ["block.height", "tradeIndex"], limit: 1}        
        baseCurrency: {is: "${tokenAddress}"}
        quoteCurrency: {is: "${DefaultTokens.BUSD.address}"}
        time: {before: "${time}"}
      ) {
        transaction {
          hash
        }
        tradeIndex
        smartContract {
          address {
            address
          }
          contractType
          currency {
            name
          }
        }
        tradeIndex
        quoteCurrency {
          symbol
          address
        }
        quotePrice
        block {
          height
        }
        baseCurrency {
          address
        }
      }
    }
  }`;

  // -------- Endpoint ----------------------
  const endpoint = "https://graphql.bitquery.io/";

  // Function which fetches the data from the API
  const response = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "BQYRgsg3l0o6F7hP74LyPMd657qC8Q2R",
      // "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
      // "Access-Control-Allow-Headers":
      //   "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
    },
    body: JSON.stringify({
      query: QUERY,
    }),
  });

  const data = await response.json();
  const currency = data.data.ethereum.dexTrades[0].quotePrice;
  return currency;
};

export const getTransactionOwnerToken = async () => {
  const QUERY = `{
    ethereum(network: ethereum) {
      dexTrades(
        options: {limit: 20, desc: "block.height"}
        exchangeName: {in: ["Pancake", "Pancake v2"]}
        any: {baseCurrency: {is: "0x580de58c1bd593a43dadcf0a739d504621817c05"}, txSender: {is: "0x1660cd15544fdf176677079a0675c8c59f020e84"}}
      ) {
        transaction {
          hash
        }
        block {
          height
        }
        buyCurrency {
          symbol
          address
        }
        sellCurrency {
          symbol
          address
        }
        buyAmount(calculate: maximum)
        sellAmount(calculate: maximum)
        any(of: time)
        exchange {
          fullName
        }
        tradeAmount(in: USD)
      }
    }
  }`;

  // -------- Endpoint ----------------------
  const endpoint = "https://graphql.bitquery.io/";

  // Function which fetches the data from the API
  const response = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "BQYRgsg3l0o6F7hP74LyPMd657qC8Q2R",
    },
    body: JSON.stringify({
      query: QUERY,
    }),
  });

  const data = await response.json();
  const currency = data.data.ethereum.dexTrades;
  return currency;
};

export const getChartInfo = async (tokenAddress, coinAddress) => {
  const QUERY = `{
    ethereum(network: ethereum) {
      dexTrades(
        options: {limit: 320, desc: "timeInterval.minute"}
        exchangeName: {is: "Pancake v2"}
        baseCurrency: {is: "${tokenAddress}"}
        quoteCurrency: {is: "${coinAddress}"}
        date: {}
      ) {
        timeInterval {
          minute(format: "%FT%TZ", count: 15)
        }
        volume: quoteAmount
        high: quotePrice(calculate: maximum)
        low: quotePrice(calculate: minimum)
        open: minimum(of: block, get: quote_price)
        close: maximum(of: block, get: quote_price)
        baseCurrency {
          name
        }
        quoteCurrency {
          name
        }
      }
    }
  }`;

  // -------- Endpoint ----------------------
  const endpoint = "https://graphql.bitquery.io/";

  // Function which fetches the data from the API
  const response = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "BQYRgsg3l0o6F7hP74LyPMd657qC8Q2R",
    },
    body: JSON.stringify({
      query: QUERY,
    }),
  });

  const data = await response.json();
  const currency = data.data.ethereum.dexTrades;

  return currency;
};
