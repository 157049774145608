import { CircularProgress } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppContext } from "../../../Pages/TradeDex/utils/Utils";
// import { useWallet } from "use-wallet";
import { getWalletData } from "../../../PooCoin";

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#0f1a1c",
    color: theme.palette.common.white,
    // padding: '0 0 0 10px',
    borderColor: "#0f1a1c",
    padding: 0,
  },
  body: {
    fontSize: 12,
    lineHeight: 1.43,
    // padding: 0,
    // paddingLeft: 10,
    color: "#fff",
    borderColor: "#0f1a1c",
    maxHeight: 300,
    overflow: "auto",
    padding: 0,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#141414",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#141722",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 100,
    maxHeight: 200,
    overflow: "auto",
    float: "right",
    fontSize: "14px !important",
    fontFamily: '"Lato",sans-serif',
    paddingTop: "0px",
  },
  row: {
    color: "#28a745 !important",
    textAlign: "right",
    "& a": {
      color: "#A4FF66",
    },
  },
  tableBody: {
    maxHeight: 300,
    overflow: "auto",
  },
  th1: {
    textAlign: "right",
    paddingRight: "10px",
    fontSize: "15px",
  },
  th2: {
    textAlign: "right",
    paddingRight: "10px",
    fontSize: "15px",
  },
  CircularProgress: {
    color: "#b2b5be",
    marginTop: "20px",
  },
  title: {
    color: "white",
    textAlign: "left",
    marginLeft: "20px",
  },
});

export default function CustomizedTables() {
  const classes = useStyles();
  // const { account } = useWallet();
  const { account } = useContext(AppContext);
  const [walletData, setWalletData] = useState([]);
  const [loading, setLoading] = useState(true);
  const tokenAddress = useSelector((state) => state.tokenInfo.tokenAddress);

  const setWalletValues = (data) => {
    if (data.length === 0) {
      setWalletData([]);
      setLoading(false);
    } else {
      setLoading(false);
      setWalletData(data);
    }
  };

  useEffect(() => {
    if (account == null) {
      setLoading(true);
      setWalletData(null);
    } else {
      getWalletData(tokenAddress, account, setWalletValues);
    }
  }, [account]);

  return (
    <div>
      <TableContainer>
        {account != null && (
          <div className={classes.title}>
            <a
              target="_blank"
              rel="noreferrer"
              className={"textBlue"}
              href={`https://etherscan.io/token/${tokenAddress}?a=${account}`}
            >
              EtherScan wallet tx
            </a>
          </div>
        )}
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.th1}>Tokens</StyledTableCell>
              <StyledTableCell className={classes.th2}>Price</StyledTableCell>
              <StyledTableCell className={classes.th2}>
                Current value
              </StyledTableCell>
              <StyledTableCell className={classes.th2}>
                Date/Time
              </StyledTableCell>
              <StyledTableCell className={classes.th2}>Tx</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {account != null ? (
              walletData != null ? (
                walletData.length > 0 ? (
                  walletData.map((row, index) => (
                    <StyledTableRow key={index} className={classes.row}>
                      <StyledTableCell
                        className={classes.th1}
                        component="th"
                        scope="row"
                      >
                        {row.buyAmount.toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell className={classes.th1}>
                        ${row.quotePrice.toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell className={classes.th1}>
                        {row.amount}
                      </StyledTableCell>
                      <StyledTableCell className={classes.th1}>
                        {row.any}
                      </StyledTableCell>
                      <StyledTableCell className={classes.th1}>
                        {row.transaction.hash.substring(0, 6)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow key={1} className={classes.row}>
                    <StyledTableCell colSpan={5} className={classes.th1}>
                      {"No Records Found!"}
                    </StyledTableCell>
                  </StyledTableRow>
                )
              ) : (
                ""
              )
            ) : (
              <StyledTableRow key={1} className={classes.row}>
                <StyledTableCell colSpan={5} className={classes.th1}>
                  {"Connect your wallet to See Records"}
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {account != null && loading && (
        <CircularProgress size={20} className={classes.CircularProgress} />
      )}
    </div>
  );
}
