import { CircularProgress } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useEffect, useState } from "react";
import { vettedValues } from "../../PooCoin/index.js";
import { storeLocalMultichart } from "../../PooCoin/util.js";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#0f1a1c",
    color: theme.palette.common.white,
    padding: 0,
    paddingLeft: 10,
    textAlignLast: "left",
    borderColor: "#0f1a1c",
  },
  body: {
    fontSize: "0.875rem",
    padding: 0,
    paddingLeft: 10,
    backgroundColor: "rgba(255,255,255,0.1)",
    color: "#fff",
    borderColor: "#0f1a1c",
    borderBottom: "#0f1a1c",
    textAlignLast: "left",
    borderRadius: 0,
  },
}))(TableCell);

const rows = Array.from(Array(1).keys()).map((item) => {
  return {
    name: "THOREUM",
  };
});

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 100,
  },
  upper: {
    textTransform: "uppercase !important",
  },
  otherName: {
    color: "#ADB5BD",
    fontSize: 12,
  },
  CircularProgress: {
    color: "#b2b5be",
    marginTop: "20px",
  },
  tokenList: {
    cursor: "pointer",
  },
});

export default function CustomizedTables(props) {
  const classes = useStyles();
  const [vettedData, setVettedData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);

  const setVettedValues = (data) => {
    if (data.length == 0) {
      setLoading(true);
    } else {
      setLoading(false);
      setVettedData(data);
    }
  };

  const addMultichartInfo = (tokenAddress) => () => {
    storeLocalMultichart(tokenAddress);
    props.onSymbol();
  };

  useEffect(() => {
    vettedValues(setVettedValues);
    getHistoryToken();
  }, []);

  const getHistoryToken = async () => {
    let hdata = localStorage.getItem("history");
    let data = JSON.parse(hdata);
    if (data != undefined && data.length > 0) {
      setHistoryData(data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <div>
      <TableContainer>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Tokens</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historyData.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell
                  className={classes.tokenList}
                  component="th"
                  scope="row"
                  onClick={addMultichartInfo(row.address)}
                >
                  <span className={classes.uppper}>{row.name}</span>
                  <div className={classes.otherName}>{row.name}</div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && (
        <CircularProgress size={20} className={classes.CircularProgress} />
      )}
    </div>
  );
}
