import { currencyEquals, ETHER, WETH } from "moonchan-sdk";
import { useContext, useMemo } from "react";
import { tryParseAmount } from "../state/swap/hooks";
import { useTransactionAdder } from "../state/transactions/hooks";
import { useCurrencyBalance } from "../state/wallet/hooks.js";
import { AppContext } from "../utils/Utils";
import { useWETHContract } from "./useContract";
export let WrapType = {
  NOT_APPLICABLE: "NOT_APPLICABLE",
  WRAP: "WRAP",
  UNWRAP: "UNWRAP",
};
const NOT_APPLICABLE = { wrapType: WrapType.NOT_APPLICABLE };
/**
 * Given the selected input and output currency, return a wrap callback
 * @param inputCurrency the selected input currency
 * @param outputCurrency the selected output currency
 * @param typedValue the user input value
 */
export default function useWrapCallback(
  inputCurrency,
  outputCurrency,
  typedValue
) {
  const { chainId, account } = useContext(AppContext);
  const wethContract = useWETHContract();
  const balance = useCurrencyBalance(account ?? undefined, inputCurrency);
  // we can always parse the amount typed as the input currency, since wrapping is 1:1
  const inputAmount = useMemo(
    () => tryParseAmount(typedValue, inputCurrency),
    [inputCurrency, typedValue]
  );
  const addTransaction = useTransactionAdder();

  return useMemo(() => {
    if (!wethContract || !chainId || !inputCurrency || !outputCurrency)
      return NOT_APPLICABLE;

    const sufficientBalance =
      inputAmount && balance && !balance.lessThan(inputAmount);

    if (
      inputCurrency === ETHER &&
      currencyEquals(WETH[chainId], outputCurrency)
    ) {
      return {
        wrapType: WrapType.WRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                try {
                  const txReceipt = await wethContract.deposit({
                    value: `0x${inputAmount.raw.toString(16)}`,
                  });
                  addTransaction(txReceipt, {
                    summary: `Wrap ${inputAmount.toSignificant(
                      6
                    )} DOGE to WDOGE`,
                  });
                } catch (error) {
                  console.error("Could not deposit", error);
                }
              }
            : undefined,
        inputError: sufficientBalance ? undefined : "Insufficient ETH balance",
      };
    }
    if (
      currencyEquals(WETH[chainId], inputCurrency) &&
      outputCurrency === ETHER
    ) {
      return {
        wrapType: WrapType.UNWRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                try {
                  const txReceipt = await wethContract.withdraw(
                    `0x${inputAmount.raw.toString(16)}`
                  );
                  addTransaction(txReceipt, {
                    summary: `Unwrap ${inputAmount.toSignificant(
                      6
                    )} WDOGE to DOGE`,
                  });
                } catch (error) {
                  console.error("Could not withdraw", error);
                }
              }
            : undefined,
        inputError: sufficientBalance ? undefined : "Insufficient ETH balance",
      };
    }
    return NOT_APPLICABLE;
  }, [
    wethContract,
    chainId,
    inputCurrency,
    outputCurrency,
    inputAmount,
    balance,
    addTransaction,
  ]);
}
