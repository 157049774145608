import { Box, useTheme } from "@material-ui/core";
import { TradeType } from "moonchan-sdk";
import { ONE_BIPS } from "../../constants";
import { useLastTruthy } from "../../hooks/useLast";
import { Field } from "../../state/swap/actions";
import { useUserSlippageTolerance } from "../../state/user/hooks";
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  warningSeverity,
} from "../../utils/prices";

export default function AdvancedSwapDetailsDropdown({ trade, ...rest }) {
  const theme = useTheme();
  const lastTrade = useLastTruthy(trade) || undefined;
  const [allowedSlippage] = useUserSlippageTolerance();
  const { priceImpactWithoutFee, realizedLPFee } =
    computeTradePriceBreakdown(lastTrade);

  const isExactIn = lastTrade
    ? lastTrade?.tradeType === TradeType.EXACT_INPUT
    : false;
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(
    lastTrade,
    allowedSlippage
  );
  const severity = warningSeverity(priceImpactWithoutFee);
  // rgb(75, 192, 192)
  return (
    <Box my={5}>
      {trade ? (
        <Box
          display="flex"
          justifyContent="space-between"
          borderRadius="10px"
          width="100%"
        >
          <Box display="flex" flexDirection="column">
            <Box
              fontSize="12px"
              fontWeight="Light"
              fontFamily="Sandro"
              color="lightGray"
              textAlign="left"
            >
              {isExactIn ? "Minimum received" : "Maximum sold"}
            </Box>
            <Box
              fontSize="13px"
              fontWeight="Bold"
              fontFamily="Sandro"
              mt={1}
              color="#ffffff"
              textAlign="left"
            >
              {isExactIn
                ? `${slippageAdjustedAmounts[Field?.OUTPUT]?.toSignificant(
                    4
                  )} ${lastTrade?.outputAmount.currency.symbol}` ?? "-"
                : `${slippageAdjustedAmounts[Field?.INPUT]?.toSignificant(4)} ${
                    lastTrade?.inputAmount.currency.symbol
                  }` ?? "-"}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box
              fontSize="12px"
              fontWeight="Light"
              fontFamily="Sandro"
              color="lightGray"
              textAlign="left"
            >
              Price Impact
            </Box>
            <Box
              fontSize="13px"
              fontWeight="Bold"
              fontFamily="Sandro"
              textAlign="left"
              mt={1}
              color={
                severity === 3 || severity === 4
                  ? "#fff"
                  : severity === 2
                  ? "#fff"
                  : severity === 1
                  ? "#fff"
                  : "#fff"
              }
            >
              {priceImpactWithoutFee
                ? priceImpactWithoutFee.lessThan(ONE_BIPS)
                  ? "<0.01%"
                  : `${priceImpactWithoutFee.toFixed(2)}%`
                : "-"}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box
              fontSize="12px"
              fontWeight="Light"
              fontFamily="Sandro"
              color="lightGray"
              textAlign="left"
            >
              Liquidity Provider Fee
            </Box>
            <Box
              fontSize="13px"
              fontWeight="Bold"
              fontFamily="Sandro"
              mt={1}
              color="#ffffff"
              textAlign="left"
            >
              {realizedLPFee
                ? `${realizedLPFee.toSignificant(4)} ${
                    lastTrade.inputAmount.currency.symbol
                  }`
                : "-"}
            </Box>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
