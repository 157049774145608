import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { AppContext } from "../../Pages/TradeDex/utils/Utils";
// import { useWallet } from "use-wallet";
import HistoryTable from "./history";
import StarredTable from "./starred";
import Wallet from "./wallet";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    padding: 0,
    boxShadow: "inherit",
    width: "98%",
    marginLeft: "1%",
  },
  tabTilteLength: {
    minWidth: "0px !important",
    textTransform: "none !important",
    padding: 8,
    Borders: "1px solid #fff",
    borderRadius: 2,
    // border: '1px solid #fff',
    color: "#fff",
    // backgroundColor: '#141414'
  },
  tabs: {
    backgroundColor: "rgba(255,255,255,0.1)",
    color: "#fff",
    // border: '0px solid rgba(255,255,255,0.1)',
    borderBottom: "1px solid #0c0c0c",
  },
  tabpanel: {
    backgroundColor: "rgba(255,255,255,0.1)",
    border: "0px solid rgba(255,255,255,0.1)",
    padding: "0 !important",
  },
  tabletab: {
    padding: 0,
  },
  walletContainer: {
    color: "#fff",
    marginTop: "20px",
  },
  walletLink: {
    textAlign: "right",
    padding: "10px",
  },
  promotedLink: {
    color: "#A4FF66",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function CenteredTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const { account, connect, reset, status } = useWallet();
  const { account } = useContext(AppContext);
  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        className={classes.tabs}
      >
        <Tab label="Wallet" className={classes.tabTilteLength} />
        <Tab label="Starred" className={classes.tabTilteLength} />
        <Tab label="History" className={classes.tabTilteLength} />
      </Tabs>
      <TabPanel value={value} index={0} className={classes.tabpanel}>
        <div>
          {/*<div className={classes.walletLink}>
            <Link to="">Restore Hidden</Link>
        </div>*/}
          {account == null ? (
            <div className={classes.walletContainer}>
              Connect your wallet to see your tokens.
            </div>
          ) : (
            <Wallet />
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabpanel}>
        <StarredTable />
      </TabPanel>
      <TabPanel value={value} index={2} className={classes.tabpanel}>
        <HistoryTable />
      </TabPanel>
    </div>
  );
}
