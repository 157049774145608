import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import DefaultToken from "../../config/default_tokens.json";
import { getAmountsOut, getReserve } from "../../PooCoin/index";
import { numberWithCommas } from "../../PooCoin/util";

const useStyles = makeStyles({
  root: {
    marginBottom: "1rem",
  },
  linkTag: {
    fontSize: "14px",
    color: "#A4FF66",
  },
});

export default function LpInfoItem(props) {
  const { lpInfo, currentTokenInfo } = props;
  const classes = useStyles();
  const [lpInfoData, setLpInfoData] = useState({});
  const [priceRate, setPriceRateData] = useState(0);
  const [lpMarketcap, setLpMarketcap] = useState(0);
  const setPriceRate = (data) => {
    setPriceRateData(data);
  };
  useEffect(() => {
    const lpdataInfo = lpInfo;
    console.log(lpdataInfo.value[3],"lpdataInfo.value[3]")
    getReserve(lpdataInfo.value[2], 0).then((reserveData) => {
      setLpInfoData({
        label: lpdataInfo.label,
        symbol: lpdataInfo.value[1],
        tokenAddress: lpdataInfo.value[0],
        lpAddress: lpdataInfo.value[2],
        reserve: reserveData,
      });
    });
    if (lpdataInfo.value[0] == DefaultToken.USDT.address) {
      setPriceRate(1);
    } else {
      getAmountsOut(
        1,
        lpdataInfo.value[0],
        DefaultToken.USDT.address,
        setPriceRate
      );
    }
  }, [lpInfo]);
  // const lpMarketcap = numberWithCommas(parseInt(priceRate * lpInfo.reserve));
  useEffect(() => {
    setLpMarketcap(numberWithCommas(parseInt(priceRate * lpInfoData.reserve)));
  }, [lpInfoData, priceRate]);
  return (
    <div className={classes.root}>
      <a
        target="_blank"
        rel="noreferrer"
        className={classes.linkTag}
        href="https://app.uniswap.org/#/swap"
      >
        Uniswap
      </a>{" "}
      | {currentTokenInfo?.name}/{lpInfoData.label} LP Holdings:
      <br />
      {lpInfoData.reserve === undefined
        ? 0
        : parseFloat(lpInfoData.reserve).toFixed(2) + lpInfoData.symbol}
      <span className={"textSuccess"}>
        {lpInfoData.reserve === undefined ? "($0)" : `($${lpMarketcap})`}
      </span>{" "}
      |
      <a
        target="_blank"
        className={classes.linkTag}
        href={`https://etherscan.io/address/0x5d0e8f88e4dfacbf98ed106c2e9dc85ee371ec34`}
      >
        {" "}
        Chart
      </a>{" "}
      |{" "}
      <a
        target="_blank"
        className={classes.linkTag}
        href={`https://etherscan.io/token/${lpInfoData.lpAddress}`}
      >
        Holders
      </a>
    </div>
  );
}
