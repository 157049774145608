import { Web3ReactProvider } from "@web3-react/core";
import "./App.css";
import Layout from "./Component/Layout";
import { getLibrary } from "./Component/walletConfiguration";
import ETHProviders from "./Pages/TradeDex/Eth-Provider";
// import { Provider } from "react-redux";
// import store from "./store";

function App() {
  return (
    // <Provider store={store}>
    <ETHProviders>
      <Web3ReactProvider getLibrary={getLibrary}>
        <div className="App">
          <Layout />
        </div>
      </Web3ReactProvider>
    </ETHProviders>
    // </Provider>
  );
}

export default App;
