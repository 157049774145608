import { Interface } from "@ethersproject/abi";
import { Pair, TokenAmount } from "moonchan-sdk";
import { useContext, useMemo } from "react";
import IUniswapV2PairABI from "./IUniswapV2Pair.json";

import { useMultipleContractSingleData } from "../state/multicall/hooks";
import { AppContext } from "../utils/Utils";
import { wrappedCurrency } from "../utils/wrappedCurrency";

const PAIR_INTERFACE = new Interface(IUniswapV2PairABI);
export let PairState = {
  LOADING: "LOADING",
  NOT_EXISTS: "NOT_EXISTS",
  EXISTS: "EXISTS",
  INVALID: "INVALID",
};
export function usePairs(currencies) {
  const { chainId } = useContext(AppContext);

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId),
      ]),
    [chainId, currencies]
  );

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        return tokenA && tokenB && !tokenA.equals(tokenB)
          ? Pair.getAddress(tokenA, tokenB)
          : undefined;
      }),
    [tokens]
  );

  // console.log(pairAddresses, "pairAddresses", IUniswapV2PairABI);
  const results = useMultipleContractSingleData(
    pairAddresses,
    PAIR_INTERFACE,
    "getReserves"
  );

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result;
      const tokenA = tokens[i][0];
      const tokenB = tokens[i][1];

      if (loading) return [PairState.LOADING, null];
      if (!tokenA || !tokenB || tokenA.equals(tokenB))
        return [PairState.INVALID, null];
      if (!reserves) return [PairState.NOT_EXISTS, null];
      const { _reserve0, _reserve1 } = reserves;
      const [token0, token1] = tokenA.sortsBefore(tokenB)
        ? [tokenA, tokenB]
        : [tokenB, tokenA];
      return [
        PairState.EXISTS,
        new Pair(
          new TokenAmount(token0, _reserve0?.toString()),
          new TokenAmount(token1, _reserve1?.toString())
        ),
      ];
    });
  }, [results, tokens]);
}

export function usePair(tokenA, tokenB) {
  return usePairs([[tokenA, tokenB]])[0];
}
