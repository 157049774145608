import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import {
  BscConnector,
  UserRejectedRequestError,
} from "@binance-chain/bsc-connector";
import { useWeb3React } from "@web3-react/core";
import { ConnectionRejectedError, UseWalletProvider } from "use-wallet";
import DefaultTokens from "../config/default_tokens.json";
import { connectType, networkValue } from "../constants";
import networkInfo from "../constants/networkInfo.json";
import About from "../Pages/About";
import Advertise from "../Pages/Advertise";
import Ape from "../Pages/Ape";
import ExternalTools from "../Pages/ExternalTools";
import Home from "../Pages/Home";
import KuchainAdvertise from "../Pages/Kuchain/KuchainAdvertise";
import KuchainHome from "../Pages/Kuchain/KuchainHome";
import Multichart from "../Pages/Multichart";
import PolygonAdvertise from "../Pages/Polygon/PolygonAdvertise";
import PolygonHome from "../Pages/Polygon/PolygonHome";
import RugCheck from "../Pages/RugCheck";
import SniperWatcher from "../Pages/SniperWatcher";
import Toilet from "../Pages/Toilet";
import Tokens from "../Pages/Tokens";
// import Trade from "../Pages/Trade";
import { Box } from "@material-ui/core";
import moon from "../Images/moon.jpeg";
// import ETHProviders from "../Pages/TradeDex/Eth-Provider";
import { Swap } from "../Pages/TradeDex/swap";
import Trending from "../Pages/Trending";
import Header from "./Header";
import { injected, walletconnect } from "./walletConfiguration";

function Layout() {
  const { activate, active } = useWeb3React();
  let localChainId = parseInt(
    localStorage.getItem("PoocoinChainId") != null
      ? localStorage.getItem("PoocoinChainId")
      : "1"
  );

  let [connectId, setConnectId] = useState(connectType.metamask);
  const connectHandle = (connect) => {
    setConnectId(connect);
  };

  const changeNetworkHandle = (chainId_) => {
    setID(chainId_);
    localStorage.setItem("PoocoinChainId", chainId_);
  };

  if (localChainId == null || isNaN(localChainId)) {
    localChainId = networkValue.Ethereum;
    localStorage.setItem("PoocoinChainId", localChainId);
  }

  let [chainId, setID] = useState(localChainId);

  let rpcUrl;
  networkInfo.forEach((network) => {
    if (network.chainId == chainId) {
      rpcUrl = network.rpc;
    }
  });

  let connector;
  const connection = async () => {
    if (active) return;
    if (connectId == connectType.metamask) {
      // connector = ({
      //   injected: { rpcUrl: rpcUrl }
      // })
      await activate(injected);
    } else if (connectId == connectType.walletconnect) {
      // connector = {
      //   walletconnect: { rpcUrl}
      // }
      await activate(walletconnect);
    } else if (connectId == connectType.binance) {
      connector = {
        bsc: {
          web3ReactConnector() {
            return new BscConnector({ supportedChainIds: [56, 97] });
          },
          handleActivationError(err) {
            if (err instanceof UserRejectedRequestError) {
              return new ConnectionRejectedError();
            }
          },
        },
      };
    }
  };

  useEffect(() => {
    connection();
  }, [connectId]);

  return (
    <React.Fragment>
      <UseWalletProvider>
        <BrowserRouter>
          <Header
            networkChainId={1}
            changeNetwork={changeNetworkHandle}
            connectControl={connectHandle}
          />
          <Box
            component="img"
            src={moon}
            alt=""
            className="pictureMove"
            sx={{
              width: "80vw",
              left: { xs: "25px", sm: "80px", md: "175px" },
            }}
          />
          <Switch>
            <Route path="/" exact component={Home} />
            {/* <Route path="/tools" exact component={Tools} /> */}
            {/* <Route path="/premium" exact component={Premium} /> */}
            {/* <Route path="/swap" exact component={Trade} /> */}
            <Route path="/promote" component={Advertise} />
            <Route path="/multichart" exact component={Multichart} />
            <Route
              path="/about"
              exact
              component={() => (
                <About tokenAddress={DefaultTokens.POOCOIN.address} />
              )}
            />
            <Route path="/trending" exact component={Trending} />
            <Route path="/rugcheck" exact component={RugCheck} />
            <Route path="/external-tools" exact component={ExternalTools} />
            <Route path="/ape" exact component={Ape} />
            <Route path="/sniper-watcher" exact component={SniperWatcher} />
            <Route path="/toilet" exact component={Toilet} />
            <Route path="/tokens/:id" exact component={Tokens} />
            <Route path="/polygon" exact component={PolygonHome} />
            <Route path="/polygonpromote" exact component={PolygonAdvertise} />
            <Route path="/kuchain" exact component={KuchainHome} />
            <Route path="/kuchainpromote" exact component={KuchainAdvertise} />
            <Route path="/trade" exact component={Swap} />
          </Switch>
        </BrowserRouter>
      </UseWalletProvider>
    </React.Fragment>
  );
}

export default Layout;
