import { Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { useCallback, useEffect, useState } from "react";
import useLast from "../../hooks/useLast";
import { useSelectedListUrl } from "../../state/lists/hooks";
import { CurrencySearch } from "./CurrencySearch";
const StyledModal = withStyles((theme) => ({
  root: {
    "& .MuiDialog-root": {
      zIndex: "1301 !important",
    },
    "&.MuiDialog-container": {
      overflowY: "hidden !important",
    },
    "& .MuiDialog-paperScrollPaper": {
      background: "tranparent !important",
      height: "350px",
      boxShadow: "0px 0px 13px 0px  #686666",
      borderRadius: "30px !important",
    },
    "& .dialoge__content__section": {
      background: "transparent",
      borderRadius: 18,
      height: 350,
      padding: "0px !important",
    },
  },
}))(Dialog);
export default function CurrencySearchModal({
  isOpen,
  onDismiss,
  onCurrencySelect,
  selectedCurrency,
  otherSelectedCurrency,
}) {
  const [listView, setListView] = useState(false);
  const lastOpen = useLast(isOpen);

  useEffect(() => {
    if (isOpen && !lastOpen) {
      setListView(false);
    }
  }, [isOpen, lastOpen]);

  const handleCurrencySelect = useCallback(
    (currency) => {
      onCurrencySelect(currency);
      onDismiss();
    },
    [onDismiss, onCurrencySelect]
  );

  const handleClickChangeList = useCallback(() => {
    setListView(true);
  }, []);
  const handleClickBack = useCallback(() => {
    setListView(false);
  }, []);

  const selectedListUrl = useSelectedListUrl();
  const noListSelected = !selectedListUrl;

  return (
    <StyledModal open={isOpen} onClose={onDismiss}>
      {noListSelected ? (
        <CurrencySearch
          isOpen={isOpen}
          onDismiss={onDismiss}
          onCurrencySelect={handleCurrencySelect}
          onChangeList={handleClickChangeList}
          selectedCurrency={selectedCurrency}
          otherSelectedCurrency={otherSelectedCurrency}
          showCommonBases={false}
        />
      ) : (
        <CurrencySearch
          isOpen={isOpen}
          onDismiss={onDismiss}
          onCurrencySelect={handleCurrencySelect}
          onChangeList={handleClickChangeList}
          selectedCurrency={selectedCurrency}
          otherSelectedCurrency={otherSelectedCurrency}
          showCommonBases={false}
        />
      )}
    </StyledModal>
  );
}
