import { Web3Provider } from "@ethersproject/providers";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import networkInfo from "../constants/networkInfo.json";

let rpcUrl;
networkInfo.forEach((network) => {
  if (network.chainId == 1) {
    rpcUrl = network.rpc;
  }
});
const injected = new InjectedConnector({});

// mainnet only
const walletconnect = new WalletConnectConnector({
  rpc: { 1: "https://mainnet.infura.io/v3/538f6602a3474dfab48d5e4728f98d13" },
  bridge: "https://bridge.walletconnect.org",
  chainId: 1,
  qrcode: true,
  pollingInterval: 2000,
  qrcodeModalOptions: {
    mobileLinks: ["metamask", "trust"],
  },
});

function getLibrary(provider) {
  const library = new Web3Provider(provider, "any");
  library.pollingInterval = 12000;
  return library;
}

export { injected, getLibrary, walletconnect };
