import { withStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  InputBase,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CustomizedButton from "../small components/CustomButton/CustomButton";
import { useUserDeadline, useUserSlippageTolerance } from "../state/user/hooks";
const StyledModal = withStyles((theme) => ({
  root: {
    "& .MuiDialog-root": {
      zIndex: "1301 !important",
    },
    "&.MuiDialog-container": {
      overflowY: "hidden !important",
    },
    "& .MuiDialog-paperScrollPaper": {
      backgroundColor: "tranparent !important",
      height: "auto",
      boxShadow: "0px 0px 13px 0px  #686666",
      borderRadius: "30px !important",
    },
    "& .dialoge__content__section": {
      background: "#F1F5FD 0% 0% no-repeat padding-box",
      borderRadius: 18,
      padding: "0px !important",
    },
  },
}))(Dialog);

const MAX_SLIPPAGE = 5000;
const RISKY_SLIPPAGE_LOW = 50;
const RISKY_SLIPPAGE_HIGH = 500;

function SlippingTolrance({ openSlippage, setopenSlippage }) {
  const theme = useTheme();
  const [userSlippageTolerance, setUserslippageTolerance] =
    useUserSlippageTolerance();
  const matches = useMediaQuery("(max-width:760px)");
  const [percentage, setpercentage] = useState(0);
  const handleClose = () => {
    setopenSlippage(false);
  };
  const [value, setValue] = useState(userSlippageTolerance / 100);
  const [error, setError] = useState(null);
  const handleChange = (evt) => {
    const { value: inputValue } = evt.target;
    setValue(parseFloat(inputValue));
  };
  const [deadline, setDeadline] = useUserDeadline();
  // console.log(deadline, "deadline");
  const [value1, setValue1] = useState(deadline / 60); // deadline in minutes
  const [error1, setError1] = useState(null);

  const handleChange1 = (evt) => {
    const { value: inputValue } = evt.target;
    if (value > 0 && value) {
      setValue1(parseInt(inputValue, 10));
    }
  };

  // Updates local storage if value is valid
  useEffect(() => {
    try {
      const rawValue = value1 * 60;
      if (!Number.isNaN(rawValue) && rawValue > 0) {
        setDeadline(rawValue);
        setError1(null);
      } else {
        setError1("Enter a valid deadline");
      }
    } catch {
      setError("Enter a valid deadline");
    }
  }, [value1, setError, setDeadline]);

  useEffect(() => {
    try {
      const rawValue = value * 100;
      if (!Number.isNaN(rawValue) && rawValue > 0 && rawValue < MAX_SLIPPAGE) {
        setUserslippageTolerance(rawValue);
        setError(null);
      } else {
        setError("Enter a valid slippage percentage");
      }
    } catch {
      setError("Enter a valid slippage percentage");
    }
  }, [value, setError, setUserslippageTolerance]);

  // Notify user if slippage is risky
  useEffect(() => {
    if (userSlippageTolerance < RISKY_SLIPPAGE_LOW) {
      setError("Your transaction may fail");
    } else if (userSlippageTolerance > RISKY_SLIPPAGE_HIGH) {
      setError("Your transaction may be frontrun");
    }
  }, [userSlippageTolerance, setError]);
  const predefinedValues = [
    { label: "0.1", value: 0.1 },
    { label: "0.5", value: 0.5 },
    { label: "1", value: 1 },
  ];
  return (
    <>
      <StyledModal
        keepMounted
        open={openSlippage}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="dialoge__content__section">
          <div
            style={{
              textAlign: "center",
              paddingBottom: "30px",
              width: matches ? "100%" : "350px",
            }}
          >
            <Box
              paddingLeft="20px"
              paddingRight="20px"
              paddingTop="25px"
              display="flex"
              style={{
                background: "#A4FF66",
              }}
              justifyContent="space-between"
              pb="20px"
            >
              <Box
                color="#000"
                fontSize="20px"
                textAlign="left"
                fontWeight="Light"
                m={2}
              >
                SETTINGS
              </Box>
              <Box>
                <ClearIcon
                  onClick={handleClose}
                  color="#000"
                  style={{
                    marginRight: "15px",
                    marginTop: "20px",
                    cursor: "pointer",
                  }}
                />
              </Box>
            </Box>
            <Divider
              style={{
                width: "100%",
                backgroundColor: "#F1F5F91F",
                height: "1px",
              }}
            />
            <Box
              color={theme.palette.common.black}
              fontSize="14px"
              textAlign="left"
              fontWeight="Light"
              paddingLeft="20px"
              mt={3}
              ml={2}
            >
              Slippage Tolerance, %
            </Box>
            <Box
              paddingLeft="20px"
              paddingRight="20px"
              mt={3}
              display="flex"
              alignItems="center"
              justifyContent="space-evenly"
              flexWrap="wrap"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                bgcolor="#b7b7b7"
                alignItems="center"
                borderRadius="16px"
                padding="1px 10px"
                marginTop={matches ? "15px" : "0px"}
                width="30%"
              >
                <InputBase
                  style={{
                    fontSize: "15.5px",
                    textAlign: "center",
                    color: theme.palette.primary.contrastText,
                    height: "44px",
                    paddingLeft: "10px",
                  }}
                  type="number"
                  placeholder="5%"
                  value={value}
                  onChange={handleChange}
                  isWarning={error !== null}
                />
              </Box>
              {predefinedValues.map(({ label, value: predefinedValue }) => {
                const handleClick = () => setValue(predefinedValue);
                return (
                  <Box
                    key={predefinedValue}
                    component={Button}
                    fontSize="15.5px"
                    padding="5px 10px"
                    onClick={handleClick}
                    bgcolor={theme.palette.primary.main}
                    style={{
                      borderRadius: "16px",
                      cursor: "pointer",
                      color: theme.palette.primary.contrastText,
                      background: theme.palette.primary.main,
                      height: "44px",
                      width: "15%",
                    }}
                  >
                    {label}
                  </Box>
                );
              })}
            </Box>
            {error && (
              <Box mt="8px" color="secondary.main">
                {error}
              </Box>
            )}
            <Box
              color={theme.palette.common.black}
              fontSize="14px"
              textAlign="left"
              fontWeight="Bold"
              paddingLeft="20px"
              mt={3}
              ml={2}
            >
              Transaction deadline, min
            </Box>
            <Box display="flex" px={3}>
              <Box
                bgcolor="#b7b7b7"
                borderRadius="16px"
                margin="auto"
                marginTop="20px"
                width="95%"
                pl={1}
              >
                <InputBase
                  style={{
                    fontSize: "15px",
                    fontFamily: "Sandro",
                    color: theme.palette.primary.contrastText,
                    height: "44px",
                    paddingLeft: "10px",
                    width: "100%",
                  }}
                  type="number"
                  placeholder="21"
                  step="1"
                  min="1"
                  value={value1}
                  onChange={handleChange1}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
              px={1}
            >
              <Box width="100%">
                <CustomizedButton>Cancel</CustomizedButton>
              </Box>
              <Box width="100%">
                <CustomizedButton>Save</CustomizedButton>
              </Box>
            </Box>

            {error1 && (
              <Box mt="8px" color="secondary.main" fontSize="13px">
                {error1}
              </Box>
            )}
          </div>
        </DialogContent>
      </StyledModal>
    </>
  );
}
export default SlippingTolrance;
