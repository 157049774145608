// var rp = require('request-promise').defaults({ json: true })
// const DefaultTokens = require("../../../../config/default_tokens.json");
// const { default: axios } = require("axios");

const history = {};

// const baseLp = "0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16";
// let currentTimeStamp = new Date().getTime() - 84600000;
// console.log(currentTimeStamp," currentTimeStamp");
// let date = new Date(currentTimeStamp).toISOString().split('.').shift() + '.000Z';
let date = new Date().toISOString().split(".").shift() + ".000Z";
const historyProvider = {
  history: history,

  getBars: function (symbolInfo, resolution, from, to, first, limit) {
    function ISODateString(d) {
      function pad(n) {
        return n < 10 ? "0" + n : n;
      }
      return (
        d.getUTCFullYear() +
        "-" +
        pad(d.getUTCMonth() + 1) +
        "-" +
        pad(d.getUTCDate()) +
        "T" +
        pad(d.getUTCHours()) +
        ":" +
        pad(d.getUTCMinutes()) +
        ":" +
        pad(d.getUTCSeconds())
      );
    }

    let prevDate = Number(Math.floor(new Date().getTime())) - 10368000000;
    console.log(prevDate);
    let fromd = new Date(prevDate).toISOString().split(".").shift() + ".000Z";
    // fromd = ISODateString(fromd);
    // let currentTimeStamp = new Date();
    // currentTimeStamp = ISODateString(currentTimeStamp);

    // const endpoint = `https://io.dexscreener.com/u/chart/bars/bsc/${symbolInfo.ticker}?from=1640908800000&to=${currentTimeStamp}&res=1440&cb=330`;
    // const endpoint = (`https://io.dexscreener.com/u/chart/bars/ethereum/${symbolInfo.ticker}?from=1640908800000&to=${currentTimeStamp}&res=1440&cb=330`);

    const QUERY = `{
			ethereum(network: ethereum) {
			  dexTrades(    
				date: {between: ["${fromd}", "${date}"]}
				baseCurrency: {is: "${symbolInfo.token0}"}
				quoteCurrency: {is: "${symbolInfo.token1}"}
			  ) {
				timeInterval {
				  minute(format: "%FT%TZ", count: 10)
				}
				volume: tradeAmount(in: USD)
				trades: count
				high: quotePrice(calculate: maximum)
				low: quotePrice(calculate: minimum)
				open: minimum(of: block, get: quote_price)
				close: maximum(of: block, get: quote_price)
			  }
			}
		  }`;

    // let QUERY = `
    // {
    // 	ethereum(network: ethereum){
    // 	dexTrades(
    // 	any: [
    // 	{
    // 	baseCurrency: {is: "${symbolInfo.token0}"}
    // 	quoteCurrency: {is: "${symbolInfo.token1}"}},
    // 	{
    // 	baseCurrency: {is: "${symbolInfo.token0}"}
    // 	quoteCurrency: {is: "${DefaultTokens.USDC.address}"}},
    // 	{
    // 	baseCurrency: {is: "${DefaultTokens.USDC.address}"}
    // 	quoteCurrency: {is: "${symbolInfo.token1}"}}
    // 	]
    // 	date: {between: ["${fromd}", "${date}"]}
    // 	tradeAmountUsd: {gt: 0}
    // 	){
    // 	timeInterval {
    // 	minute(format: "%FT%TZ", count: 30)
    // 	}
    // 	buyCurrency: baseCurrency {
    // 	symbol
    // 	address
    // 	}
    // 	buyAmount: baseAmount
    // 	buyAmountInUsd: baseAmount
    // 	sellCurrency: quoteCurrency {
    // 	symbol
    // 	address
    // 	}
    // 	sellAmountInUsd: quoteAmount
    // 	tradeAmount(in: USD)
    // 	volume: quoteAmount
    // 	trades: count
    // 	high: quotePrice(calculate: maximum)
    // 	low: quotePrice(calculate: minimum)
    // 	open: minimum(of: block, get: quote_price)
    // 	close: maximum(of: block, get: quote_price)
    // 	}}
    // 	}

    // `;

    const endpoint = "https://graphql.bitquery.io";
    return fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "BQYRgsg3l0o6F7hP74LyPMd657qC8Q2R",
        // "X-API-KEY": "BQYRgsg3l0o6F7hP74LyPMd657qC8Q2R",
      },
      body: JSON.stringify({
        query: QUERY,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.data.ethereum.dexTrades);
        if (data.Response && data.Response === "Error") {
          return [];
        }

        if (
          data.data.ethereum.dexTrades.length &&
          data.data.ethereum.dexTrades != null
        ) {
          var bars = data.data.ethereum.dexTrades.map((el) => {
            // console.log(el.volume);
            return {
              time: new Date(el.timeInterval.minute), //TradingView requires bar time in ms
              low: el.low,
              high: el.high,
              open: el.open,
              close: el.close,
              volume: el.volume,
            };
          });
          if (first) {
            var lastBar = bars[bars.length - 1];
            history[symbolInfo.name] = { lastBar: lastBar };
          }
          return bars;
        } else {
          return [];
        }
      });
  },
};

export default historyProvider;
