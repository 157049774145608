/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/alt-text */
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import React, { useEffect, useMemo, useState } from "react";
import Input from "../Component/basic/input";
import Tab from "../Component/basic/tab";
import Panel from "../Component/multichart/panel";
import TokenSelect from "../Component/TokenSelect";
import DefaultTokens from "../config/default_tokens.json";
import { APIURL } from "../constants";
import leftPoster from "../Images/leftposter.gif";
import { storeLocalMultichart } from "../PooCoin/util";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      paddingRight: 10,
    },
    color: "black",
    [theme.breakpoints.down("xs")]: {
      "& > *": {
        paddingRight: 0,
      },
    },
  },
  input: {
    display: "none",
  },
  button: {
    margin: theme.spacing(1),
    float: theme.right,
  },
  rightTitle: {
    color: "#ffffff",
    paddingBottom: 10,
  },
  inputWidth: {
    width: "100%",
    padding: "20px",
  },
  tabContainer: {
    backgroundColor: "rgba(255,255,255,0.1) !important",
    minHeight: "700px !important",
  },
  leftSide: {
    [theme.breakpoints.down("sm")]: {
      // display: 'none',
    },
  },
  leftSideOther: {
    [theme.breakpoints.down("xs")]: {
      "& .MuiGrid-grid-xs-4": {
        flexBasis: "100%",
        maxWidth: "100%",
      },
      width: "100%",
      "& .row > .cell": {
        display: "none",
      },
    },
  },
  rightSide: {
    marginTop: 5,
    padding: "30px 10px 10px 10px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      minWidth: "400px",
      position: "relative",
      marginTop: "30px",
      marginLeft: "15%",
    },
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      marginTop: "4%",
      marginLeft: "0%",
      width: "100%",
      minWidth: "300px",
    },
  },
  searchInput: {
    paddingLeft: 20,
    marginTop: 10,
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 5,
    },
  },
  bannerImage: {
    objectFit: "cover",
  },
  video: {
    width: "100%",
    height: "250px",
    objectFit: "cover",
  },
  iconBtn: {
    backgroundColor: "#fff",
    height: 35,
    width: 35,
    top: 10,
    float: "left",
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  iconBtnRight: {
    backgroundColor: "#fff",
    float: "left",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
    },
  },
  iconPadding: {
    float: "right",
  },
  iconPaddingRight: {
    paddingTop: 10,
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
}));

export default function Multichart() {
  const classes = useStyles();
  const [showMode, setShowMode] = React.useState(1);
  const [bannerMulti, setBannerMulti] = useState(null);
  const [bannerLink, setBannerLink] = useState(null);
  const [bannerExtension, setBannerExtension] = useState("image");
  const handleChange = () => {
    setShowMode(!showMode);
  };

  const handleChangeRight = () => {
    setShowMode(!showMode);
  };

  const handleTokenPropsChange = (tokenInfo) => {
    storeLocalMultichart(tokenInfo.address);
    setMultichartData(JSON.parse(localStorage.getItem("multichart")));
  };

  const inputHandle = (tokenAddress) => {
    storeLocalMultichart(tokenAddress);
    setMultichartData(JSON.parse(localStorage.getItem("multichart")));
  };

  const [multichartData, setMultichartData] = useState(
    JSON.parse(localStorage.getItem("multichart"))
  );

  if (multichartData == null) {
    storeLocalMultichart(DefaultTokens.POOCOIN.address);
  }
  // let multichartData = ;

  const onSymbol = () => {
    setMultichartData(JSON.parse(localStorage.getItem("multichart")));
  };
  useEffect(() => {
    let hdata = localStorage.getItem("multichart");
    let array = [];
    let data = JSON.parse(hdata);

    if (data != undefined && data.address.length > 0) {
      data = data.address.filter(
        (item, index) => data.address.indexOf(item) === index
      );
      localStorage.setItem("multichart", JSON.stringify({ address: data }));
    }
    return [];
  }, []);

  let leftContainer = (
    <div className={showMode ? classes.leftSide : classes.leftSideOther}>
      <div className={"row"}>
        <div className={"cell"}>
          <a href="#" target="_blank" rel="nofollow noreferrer">
            {/* <img src={addIcon} width="100%" />  */}
            {bannerExtension == "image" && (
              <img
                src={
                  bannerMulti == null ? leftPoster : APIURL + "/" + bannerMulti
                }
                className={classes.bannerImage}
                width="970"
                height={250}
              ></img>
            )}
            {bannerExtension == "video" && (
              <video className={classes.video} controls>
                <source
                  src={
                    bannerMulti == null
                      ? leftPoster
                      : APIURL + "/" + bannerMulti
                  }
                />
              </video>
            )}
          </a>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div className={classes.searchInput}>
          <div style={{ maxWidth: "400px" }}>
            <TokenSelect
              inputHandle={inputHandle}
              tokenProps={handleTokenPropsChange}
            />
          </div>
        </div>
        <div className={classes.iconPadding}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            className={classes.iconBtn}
            onClick={handleChange}
          >
            <ZoomOutMapIcon />
          </IconButton>
        </div>
      </div>
      <Grid item xs={12} lg={12} container>
        {multichartData != null &&
          multichartData.address.map((data, index) => (
            <Grid item xs={12} lg={4} style={{ padding: "5px" }} key={index}>
              <Panel tokenAddress={data} index={index} />
            </Grid>
          ))}
      </Grid>
    </div>
  );

  let container;

  if (showMode) {
    container = (
      <Container maxWidth="xl">
        <Grid className={classes.subContainer} container item xs={12}>
          <Grid item xs={11} lg={9} md={8} sm={12}>
            {leftContainer}
          </Grid>
          <Grid item xs={12} lg={3} md={4} sm={6} className={classes.rightSide}>
            <div className={classes.iconPaddingRight}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                className={classes.iconBtnRight}
                onClick={handleChangeRight}
              >
                <ZoomOutMapIcon />
              </IconButton>
            </div>

            <div className={classes.inputWidth}>
              <Input />
            </div>
            <Tab className={classes.tabContainer} onSymbol={onSymbol} />
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    container = (
      <Grid container item xs={12}>
        <Grid item xs={12}>
          {leftContainer}
        </Grid>
      </Grid>
    );
  }
  const getBannerImage = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${APIURL}/getBanner.php?type=multichart`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.length > 0) {
          setBannerMulti(result[0].bannerImage);
          setBannerLink(result[0].bannerLink);
          setBannerExtension(result[0].bannerExtension);
        } else {
          setBannerMulti(null);
          setBannerLink(null);
          setBannerExtension(null);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useMemo(() => {
    getBannerImage();
  }, []);
  return <div className={classes.root}>{container}</div>;
}
